<template>

  <div :style=" {position:flot ? 'absolute' : 'relative'}" class="bgC">
    <div class="title">
      <div class="lineH logo" @click="switchTab(0)">
        <img alt="" class="logoImg2" src="@/assets/homeImg/logo2.png" srcset=""/>
        <img class="logoImg" src="@/assets/homeImg/logo.png">
        <!--        <span class="logoName">{{ $t('navBar.companyName') }}</span>-->
      </div>
      <!--      :class="[idIndex === 0 ? 'txtactive' : '']"-->
      <div style="display: flex">
        <div class="lineH" @click="switchTab(0)">
          <span class="line Navtext">{{ $t('navBar.Home') }}</span>
          <span :class="[idIndex === 0 ? 'active' : '']"></span>
        </div>
        <div class="lineH" @click="switchTab(1)">
          <span class="line Navtext">{{ $t('navBar.who') }}</span>
          <span :class="[idIndex === 1 ? 'active' : '']"></span>
        </div>
        <div class="lineH" @click="switchTab(2)">
          <span class="line Navtext">{{ $t('navBar.what') }}</span>
          <span :class="[idIndex === 2 ? 'active' : '']"></span>
        </div>
        <div class="lineH" @click="switchTab(3)">
          <span class="line Navtext">{{ $t('navBar.How') }}</span>
          <span :class="[idIndex === 3 ? 'active' : '']"></span>
        </div>
        <div class="lineH" @click="switchTab(4)">
          <span class="line Navtext">{{ $t('navBar.People') }}</span>
          <span :class="[idIndex === 4 ? 'active' : '']"></span>
        </div>
        <div class="lineH" @click="switchTab(5)">
          <span class="line Navtext">{{ $t('navBar.News') }} </span>
          <span :class="[idIndex === 5 ? 'active' : '']" class="linegray"></span>
        </div>
      </div>
      <div class="lineH" style="margin-right: 0">
        <div @click="contactUs" class="btn"><img class=" icon1" src="@/assets/homeImg/lxwm.png">{{ $t('navBar.callWe') }}</div>
        <!--     <img src="@/">-->
        <div>
          <img class="icon1" src="@/assets/homeImg/enandzh.png">
          <span :style="{ fontWeight: lang == 'en' ? '600' : '100' }" style="color: #ffffff;font-size: 16Px;"
                @click="changeLang('en')">EN</span>
          <span style="color: #ffffff;font-size: 16Px;">/</span>
          <span :style="{ fontWeight: lang == 'zh' ? '600' : '100', width: '4rem' }"
                style="color: #ffffff;font-size: 16Px;"
                @click="changeLang('zh')">中文</span>
        </div>
      </div>
    </div>
  </div>

<!--  <div v-if="DialogFlag" class="Dialog">-->
<!--    <div class="DialogTitle">-->
<!--      <div class="DialogTitle_1">联系我们</div>-->
<!--      <div class="DialogTitle_2" @click="dialogClose()">x</div>-->

<!--    </div>-->
<!--    <div class="DialogtextBox">-->
<!--      <div>公司名称:南京寰柏生物科技有限公司 </div>-->
<!--      <div class="Dialogtext1">公司地址:南京市浦口区浦云路266号青云大厦 </div>-->
<!--      <div class="Dialogtext3">公司电话:025-52331447</div>-->
<!--      <div class="Dialogtext2">公司传真:test@huanbobiotech.com</div>-->
<!--      <div class="Dialogtext2">公司邮箱:test@huanbobiotech.com</div>-->
<!--    </div>-->

<!--  </div>-->
</template>
<script setup>
import { ref, defineProps } from 'vue'
import { useI18n } from 'vue-i18n'
import router from '@/router'

const { locale } = useI18n()

const lang = ref(localStorage.getItem('lang') || (navigator.language || 'en').toLocaleLowerCase().split('-')[0] || 'en')
defineProps({
  idIndex: Number,
  flot: {
    type: Boolean,
    default: true
  }

})
const changeLang = val => {
  locale.value = val
  lang.value = val
  localStorage.setItem('lang', val) // 重要！下面遇到问题里解释
}

// const DialogFlag = ref(false)
const  contactUs = ()=>{
  router.push({ path: '/Map', query:{num:99} })



}

function switchTab (type) {
  if (type === 0) {
    router.push({ path: '/' })
  }
  if (type === 1) {
    router.push({ path: '/whoWeAre' , query:{num:1} })
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }
  if (type === 2) {
    router.push({ path: '/weOffer' })
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }
  if (type === 3) {
    router.push({ path: '/doIt',query:{num:3} })
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }
  if (type === 4) {
    router.push({ path: '/peopleAndValue' })
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }
  if (type === 5) {
    router.push({ path: '/news',query:{num:5} })
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

}
</script>
<style lang="scss" scoped>
.Dialog{
  width: 500px;
  height: 300px;
  background: pink;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999999999;
  background: url("@/assets/homeImg/ditubgc.png")no-repeat;
  background-size: 100%,100%;
  padding:  20px;
border-radius: 5px;
  .DialogtextBox{
    display: flex;
  flex-direction: column;
    justify-content:space-around;
    padding: 0 20px;
    height: 85%;
    div{
      color: #fff;
      font-size: 20px;
      font-weight: 600;

    }
  }
  .DialogTitle{
    width: 100%;
    height: 15%;
    display: flex;
    justify-content: space-between;
   padding: 0 20px;
    color: #FFFFFF;
.DialogTitle_1{
  font-size: 25px;
  font-weight: 600;
  line-height: 40px;

}
    .DialogTitle_2{
      font-size: 25px;
      font-weight: 600;
cursor: pointer;
    }


  }

}

.logo {
  width: 278px;
  height: 100%;


}

.logoImg {
  width: 209px;
  height: 27px;
  object-fit: contain;

}

.logoImg2 {
  width: 46px;
  height: 46px;
  margin-right: 16px;
  object-fit: contain;
}

//.linegray{
//  position: absolute;
//  bottom: 5px;
//  display: inline-block;
//  width: 100%;
//  height: 3px;
//  background-color: rgba(255,255,255,.3);
//  box-sizing: border-box;
//}


.icon1 {
  width: 16px;
  height: 16px;
  vertical-align: middle;
  margin-right: 8px;
  font-size: 15px;
}


.barPic {
  height: 750px;
  width: 100%;
  // background-color: aquamarine;
}

.line {
  font-size: 17px;
  font-weight: normal;
  color: #202137;
  display: inline-block;
  text-align: center;
  box-sizing: border-box;
}

.pad {
  padding-top: 20px;
  box-sizing: border-box;
}

.txtactive {
  color: #0b99f7;
}

.Navtext {
  color: #ffffff;
}

.active {
  position: absolute;
  bottom: 0px;
  display: inline-block;
  width: 100%;
  height: 3px;
  background-color: #FE6601;
  box-sizing: border-box;
}

.bgC {
width: 100%;
  background-color: transparent;
  padding: 0 60px;
  box-sizing: border-box;
  z-index: 99;
}

.title {
  height: 85px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(255, 255, 255, .3);

  .btn {
    display: block;
    width: 150px;
    // height: 32px;
    font-size: 15px;
    color: #ffffff;
    margin-right: 25px;

  }

  .lineH {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    position: relative;
    margin-right: 45px;
    cursor: pointer;

    .logoName {
      font-size: 27px;
      font-family: 'Adobe Heiti Stde';
      font-weight: normal;
      color: #3669bd;
      width: 220px;
      margin-right: 115px;
    }
  }
}
</style>
