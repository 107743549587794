<template>
<!--  <tabBar v-if="flag"  :idIndex="0"></tabBar>-->

  <div class="main">
   <div class="box">
     <div  :class="esMate()&&['animate__fadeInRight','yc1']"  class="venetian animate__animated" >
       <img src="@/assets/homeImg/bgq.png">
       <div class="activeVenetian">
         <div class="IntroduceBox">
           <div class="title">
             办公区
           </div>
           <div class="brevity">
           </div>
           <div class="Introduc">
             {{$t("ourEnvironment.text1")}}
           </div>

         </div>

       </div>

     </div  >
     <div  :class="esMate()&&['animate__fadeInRight','yc2']" class="venetian animate__animated">
       <img src="@/assets/homeImg/qt.png">
       <div class="activeVenetian">
         <div class="IntroduceBox">
           <div class="title">
             前台
           </div>
           <div class="brevity">
           </div>
           <div class="Introduc">
             {{$t("ourEnvironment.text2")}}

           </div>

         </div>

       </div>

     </div>
     <div  :class="esMate()&&['animate__fadeInRight','yc3']"  class="venetian animate__animated">
       <img src="@/assets/homeImg/hys.png">
       <div class="activeVenetian">
         <div class="IntroduceBox">
           <div class="title">
             会议室
           </div>
           <div class="brevity">
           </div>
           <div class="Introduc">
             {{$t("ourEnvironment.text3")}}

           </div>

         </div>

       </div>

     </div>
     <div  :class="esMate()&&['animate__fadeInRight','yc4']" class="venetian animate__animated">
       <img src="@/assets/homeImg/bgsdm.png">
       <div class="activeVenetian">
         <div class="IntroduceBox">
           <div class="title">
             休闲区
           </div>
           <div class="brevity">
           </div>
           <div class="Introduc">
             {{$t("ourEnvironment.text4")}}
           </div>

         </div>

       </div>

     </div>
     <div  :class="esMate()&&['animate__fadeInRight','yc5']" class="venetian animate__animated">
       <img src="@/assets/homeImg/bgdt.png">
       <div class="activeVenetian">
         <div class="IntroduceBox">
           <div class="title">
             办公室大厅
           </div>
           <div class="brevity">
           </div>
           <div class="Introduc">
             {{$t("ourEnvironment.text5")}}

           </div>

         </div>

       </div>


     </div>
   </div>
  </div>
</template>
<script setup>
// import tabBar from '@/components/tabBar/tabIndex.vue'
import { defineProps, toRefs } from 'vue'
import { useRoute } from 'vue-router'
const props = defineProps({
  flag: {
    type: Boolean,
    default: true,
  },
  scrIndexActive:{
    type: Number,
  },

})
const {scrIndexActive } = toRefs(props)

// const scrIndexActive = ref()

//   scrIndexActive.value = toRefs(props).scrIndex
// console.log(scrIndexActive.value,111)


const Route = useRoute() //获取到值
const  esMate = ()=>{
  if (scrIndexActive.value===3||scrIndexActive.value===0){
    return true
  }
  if (Route.query.num){
    return true
  }
}

</script>
<style lang="scss" scoped>

.yc1{
  animation-duration:.3s;//重新设置动画时间

}
.yc2{
  animation-duration:.6s;//重新设置动画时间

  animation-delay:.3s;

}
.yc3{
  animation-duration:.8s;//重新设置动画时间
  animation-delay:.6s;


}
.yc4{
  animation-duration:1s;//重新设置动画时间
  animation-delay:.8s;

  //animation-delay:1s;
}

.yc5{
  //-webkit-animation-delay:1s;//延迟执行动画
  //animation-delay:1.2s;
  animation-duration:1.2s;//重新设置动画时间
  animation-delay:1.1s;


}


.main {
  width: 100%;

  height: 100vh;
.box{
  width: 1920px;
  display: flex;

}
  .venetian {
    height: 100vh;
    min-width: 20vw;
    position: relative;

    img {
      height: 100%;
     min-width: 20vw;
    }

    .activeVenetian {
      position: absolute;
      top: 0;
      left: 0;
      height: 100vh;
      width: 100%;
      opacity: 0;
      background: rgba(36, 136, 184, .7);
      background-size: 100%, 100%;


      .IntroduceBox {
        width: 287px;
        height: 350px;
        position: absolute;
        top: 50%;
        left: 50%;
        padding: 0 20px;
        transform: translate(-50%, -50%);

        .title {
          white-space: nowrap;
          text-align: center;
          font-size: 3.7rem;
          font-weight: normal;
          color: #FFFFFF;
        }

        .brevity {
          margin: 13px 0 20px 0;
          font-size: 19px;
          font-weight: normal;
          color: #FFFFFF;
          text-align: center;
        }

        .Introduc {
          font-size: 16Px;
         width: 100%;
          font-weight: normal;
          color: #FFFFFF;
          line-height: 3.2rem;
          text-indent: 3.2rem;
          text-align: justify;
        }
      }

      &:hover {
        transition: all 1s;
        opacity: 1;
      }

    }
  }


}
</style>
