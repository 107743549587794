<template>
  <!--  <iframe frameborder='0' height="1080" marginheight='0' marginwidth='0' scrolling='no'-->
  <!--          src="https://lbs.amap.com/tools/showmap/?1_1920_1080_118.610712_32.020322&=%E5%8D%97%E4%BA%AC%E5%AF%B0%E6%9F%8F%E7%94%9F%E7%89%A9%E7%A7%91%E6%8A%80%E6%9C%89%E9%99%90%E5%85%AC%E5%8F%B8&=%E6%B5%A6%E4%BA%91%E8%B7%AF&=&=025-52331447&=%E5%9C%B0%E5%9D%80%EF%BC%9A%E6%B5%A6%E5%8F%A3%E5%8C%BA%E6%B5%A6%E4%BA%91%E8%B7%AF266%E5%8F%B7%E9%9D%92%E4%BA%91%E5%A4%A7%E5%8E%A6"-->
  <!--          width="1920"-->
  <!--  ></iframe>-->
  <tabBar v-if="flag" :idIndex="ide"></tabBar>



    <div class="main">

      <div class="dhk">
        <img style="width: 5vw;height: 5vw" src="@/assets/homeImg/MapLogo.png">
        <div class="dhktext">
          <div style="margin-top: 2rem;
           white-space: nowrap;
          font-size: 1.8rem;
          font-weight: normal;
          overflow: hidden;

          color: #FFFFFF;">
            南京寰柏生物科技有限公司
          </div>
          <div style="margin-top: 2rem;
              height: 1.6vh;
           white-space: nowrap;
            overflow: hidden;
             font-size: 1.4rem;
             font-weight: normal;
             color: #FFFFFF;">
        浦云路266号青云大厦B座17层1701室
          </div>
        </div>
      </div>
<!--      <img class="map" src="@/assets/homeImg/Map.png">-->

      <div class="floatingWindow animate__animated"  :class="esMate()&& ['animate__zoomIn']">
     <div style="position: relative">
       <div class="text1">
         联系我们
       </div>
       <div class="box">
         <img class="icon" src="@/assets/homeImg/phone.png">
         <div class="text2">电话</div>
         <div class="text3">025-52331447</div>
       </div>

       <div>
         <img class="icon" src="@/assets/homeImg/emli.png">
         <div class="text2">邮箱</div>
         <div class="text3">{{'service@huanbobiotech.com'}}</div>
       </div>
     </div>

      </div>

    </div>
  <img src="@/assets/homeImg/show.png" style="position: absolute;top: 0;left: 0;width: 100%;height: 100%">

</template>
<script setup>
import tabBar from '@/components/tabBar/tabIndex.vue'
import { defineProps, toRefs } from 'vue'
import { useRoute } from 'vue-router'


const props = defineProps({

  scrIndexActive:{
    type: Number,
  },
  flag:{
    type:Boolean,
    default:true

  }
})

const router = useRoute()
// eslint-disable-next-line no-unused-vars
const  ide = router.query.num ? router.query.num : 0

const {scrIndexActive,flag } = toRefs(props)

// const scrIndexActive = ref()

//   scrIndexActive.value = toRefs(props).scrIndex
// console.log(scrIndexActive.value,111)


const  esMate = ()=>{
  if (scrIndexActive.value===4||scrIndexActive.value===0){
    return true
  }
}



</script>
<style lang="scss" scoped>

@media screen and (max-width: 980px){
  .dhktext{
    display: none;
  }
}


.main {
  width: 100%;

  height: 100vh;
  position: relative;
  background: url("@/assets/homeImg/Map.png");
  background-size:100%,100%;
  .floatingWindow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 363px;
    height: 462px;
    background: url("@/assets/homeImg/ditubgc.png");
    right: 243px;
    padding: 36px;
    z-index: 99;
    border-radius: 4px;
    .box {
      border-bottom: 1px solid #e2e2e2;
      padding-bottom: 40px;
      margin-bottom: 40px;
    }

    .text1 {
      font-size: 3.2rem;
      margin-bottom: 6.6rem;
      color: #FFFFFF;

    }

    .text2 {
      margin: 1.2rem 0;
      font-size: 20px;
      font-weight: normal;
      color: #FFFFFF;
    }

    .text3 {
      font-size: 20px;
      font-weight: normal;
      color: #FFFFFF;
    }

    .icon {
      width: 23px;
      height: 23px;
    }
  }

  .dhk {

    width: 20vw;
    height: 15vh;
    position: absolute;
    top: 22vw;
    left: 45.8vw;
    background: url("@/assets/homeImg/dilo.png") no-repeat;
    border-radius: 5px;
    background-size: contain;
    display: flex;
    padding: 6px 6px;


    img {
      width: 90px;
      height: 90px;
      margin-right: 27px;
    }
  }

  .map {
    width: 100%;
    height: 100%;
    object-fit: none;

  }
}
</style>
