import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import 'bootstrap-icons/font/bootstrap-icons.scss'
// import 'lib-flexible'
import i18n from './i18n'
import '@/assets/style/init.css'
import './filters/flexible'
import 'animate.css';
createApp(App).use(router).use(i18n).mount('#app')
