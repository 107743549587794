// zh.js
export default {
  common: {
    more: '查看更多'
  },
  navBar: {
    companyName: '寰柏生物',
    Home: 'Home',
    who: 'Who We Are',
    what: 'What We Offer',
    How: 'How We Do It',
    People: 'People & Value',
    News: 'News & Views',
    callWe: '联系我们'
  },
  bottoms: {
    who: 'Who We Are',
    what: 'What We Offer',
    how: 'How We Do It',
    our: 'Our People & Values',
    news: 'News & Views',
    car: 'Careers',
    companyAddress: 'Address:Room 1701, 17 / F, Block B, Qingyun Building, 266 Puyun Road',
    companyPhone: 'Hotline: 15050560903',
    relation: 'contact us'
  },

  homePage: {
    companyInfoTitle: '企业介绍',
    companyInfoTxt:'南京寰柏生物科技有限公司成立于2017年，是一家专注于心血管生物医药开发的公司。寰柏生物致力于满足临床未被满足的治疗需求，设计、筛选和开发一系列的创新药物，提供优质可及的创新治疗方案，提升患者生命质量。寰柏生物的服务涵盖医药临床前研究的全过程，包括药物开发、药理学研究及血清标记物ELISA试剂盒开发。目前，公司已拥有一支具有丰富经验、熟练新药研发的核心技术团队，并设有生物安全Ⅱ级细胞房。 ',
    homeMidOne: '全球专科',
    homeMidTwo: '覆盖使用人群',
    homeMidThree: '研究团队',
    disease: '疾病科普',
    diseaseInfo:
      '京寰柏生物科技有限公司成立于2017年，是一家专注于心一家专注于心血管生一家专注于心血管生一家专注于心血管生一家专注于心血管生一家专注于心血管生一家专注于心血管生一家专注于心一家专注于心血管生血管生一家专注于心血管生血管生物医药开发的公司，公司服务涵盖医药临床前研究的全过程，主要包括药物开发、药理学研究及血清标记物ELISA试剂盒开发。目前，公司已拥有一支具有丰富经验、熟练新药研发的核心技术团队，设有生物安全Ⅱ级细胞房......',
    ourTeam: '我们的团队',
    callWe: '联系我们',
    answers: '专业服务团队解答',
    quick: '快速了解服务内容',
    solutions: '提供合适解决方案',
    inputCompanyName: '请输入公司名称',
    inputName: '请输入名称',
    inputCall: '请输入联系方式',
    inputSev: '请输入服务需求',
    submit: '确认提交',
    lookMore: '查看更多',
    infoCompanyName: '南京寰柏生物科技有限公司',
    NewCenter: '新闻中心',
    detail: '详情'
  },
  whoWeAre: {
    who: 'Who We Are',
    assistant: 'Unlocking the molecular compounds for our patients',
    introduce:"南京寰柏生物科技有限公司成立于2017年，是一家专注于心血管生物医药开发的公司。寰柏生物致力于满足临床未被满足的治疗需求，设计、筛选和开发一系列的创新药物，提供优质可及的创新治疗方案，提升患者生命质量。寰柏生物的服务涵盖医药临床前研究的全过程，包括药物开发、药理学研究及血清标记物ELISA试剂盒开发。目前，公司已拥有一支具有丰富经验、熟练新药研发的核心技术团队，并设有生物安全Ⅱ级细胞房。"

    ,introduce2:"南京寰柏生物科技有限公司成立于2017年，是一家专注于心血管生物医药开发的公司。寰柏生物致力于满足临床未被满足的治疗需求，设计、筛选和开发一系列的创新药物，提供优质可及的创新治疗方案，提升患者生命质量。寰柏生.....",
    title:"南京寰柏生物科技有限公司",
    text:"公司介绍",
    t:"我们是谁",
    b1:"多项",
    b2:"查看详细",
    b3:"千万",



    x1:"拥有多项全球专利",
    x2:"覆盖数千万患者",
    x3:"国际研发团队"
  },
  weOffer: {
    offer: 'What We Offer'
  },
  howWeDoIt: {
    How: 'How We Do It',
    context:
      '疾病的内容介绍各国或地区定义“罕见病”的标准不同（图豌豆Sir）' +
      '作为最早提出“罕见病”概念的国家，美国在1983年颁布的《孤儿药法案》中提出双重定义：' +
      '在美国患者人数<20万；患者人数≥20万，但针对该疾病所开发的药物销售额不足以抵偿研发。'
  },
  peopleAndValue: {
    people: 'People & Value',
    team: 'Our Mission',
    companyAddress: 'Address of company:',
    partner: 'Partner with us',
    content:
      'To bring hopes to all patient with orphan and chronic metabolic disease.' +
      'Billions of patients are battling against those disease, waiting for a effective treatment, waiting for the truth of a dream deliver them to a normal, colorful life.' +
      'At Huanbo, we never make our patients hopeless.'
  },
  newsAndValue: {
    news: 'News & Views'
  }
  ,
  NewsJBList:{
    tlt:"疾病科普",
    topTitle:"各国或地区定义“罕见病”的标准不同，美国在1983年颁布的《孤儿药法案》中提出双重定义：在美国患者人数<20万；患者人数≥20万，但针对该疾病所开发的药物销售额不足以抵偿研发。",
    title1:"什么是肺动脉高压？",
    title2:"肺动脉高压分为哪几类？",
    title3:'肺动脉高压给身体带来什么影响？',
    title4:'多少患者正在罹患肺动脉高压，他们能被治愈吗？',
    title5:'什么是糖尿病？',
    title6:'糖尿病的患病率',
    title7:'糖尿病的分型',
    title8:'糖尿病的主要诊断依据',
    title9:'糖尿病的主要症状',
    title10:"什么是心力衰竭？",
    title11:"心力衰竭的发病率是多少？",
    title12:"心力衰竭的主要类型？",
    title13:"心力衰竭的基本病因是什么？",
    title14:"心力衰竭的主要症状是什么？",
    title15:"右心衰竭的主要症状是什么？",
    title16:"全心衰竭的主要症状是什么？",
   title17:"急性心力衰竭的主要症状是什么？",

    text1:"肺动脉高压(pulmonary hypertension，PH)是由多种异源性疾病（病因）和不同发病机制所致肺血管结构或功能改变，引起肺血管阻力和肺动脉压力....",
    text2:"临床上将肺动脉高压(PH)分为 5大类 1.动脉性 PH(pulmonary arterial hypertension，PAH)左心疾病所致的PH肺部疾病和或低氧所致的PH慢性...." ,

    text3:"随病情进展可有如下表现：" +
      "1.呼吸困难：最早出现，也最常见。表现为进行性活动后气短，病情严重的在休息时也可出现,疲劳、乏力运动耐量减低....",
    text4:"多少患者正在罹患肺动脉高压，他们能被治愈吗？" +
      "每百万成人中有~15-52例患者。更多的病例登....",
    text5:'糖尿病(Diabetes Mellitus, DM)是一组由多病因引起以慢性高血糖为特征的代谢性疾病，是由于胰岛素分泌和(或)利用缺陷所引起。慢性进行性病变....',
    text6:"糖尿病是常见病、多发病，是严重威胁人类健康的世界性公共卫生问题。目前在世界范围内，糖尿病患病率、发病率急剧上升。据国际糖尿....",
    text7:'糖尿病的分型采用WHO（1999年）的糖尿病病因学分型体系，根据病因学证据将糖尿病分为4种类型，即1型糖尿病（T1DM）、2 型糖尿病（T2DM）....',
    text8:'1.空腹血糖、随机血糖或OGTT2h血糖是诊断 糖尿病的主要依据，没有糖尿病典型临床症状 时必须重复检测以确认诊断。\n' +
      '2.在有严格质量控制的实验室....',
     text9:'基本临床表现：\n' +
       '1.代谢紊乱症状群血糖升高后因渗透性利尿引起多尿，继而口渴多饮;外周组织对葡萄糖利\n' +
       '用障碍，脂肪分解增多、蛋白质代谢负平衡....',
    text10:"心力衰竭(heartfailure，HF)是各种心脏结构或功能性疾病导致心室充盈和(或)射血功能受损，心排血量不能满足机体组织代谢需要，以肺循环和....",
    text11:"慢性心力衰竭(Chronicheartfailure，CHF)是心血管疾病的终末期表现和最主要的死因，是21世纪心血管领域的两大挑战之一据我国2003。年的....\n",
    text12:"一、左心衰竭、右心衰竭和全心衰竭\n" +
      "左心衰竭由左心室代偿功能不全所致，以肺循环淤血为特征，临床上较为常见...." ,

    text13:"一、心肌损害\n" +
      "1.原发性心肌损害；\n" +
      "2.继发性心肌损害； \n" +
      "二、心脏负荷过重：压力负荷(后负荷)过重：见于高血压、肺动脉高压等左....\n",

    text14:"一、左心衰竭以肺循环淤血及心排血量降低为主要表现：\n" +
      "1.不同程度的呼吸困难：①劳力性呼吸困难:是左心衰竭最早出现的症状。②端坐呼吸：肺淤血...." ,
    text15:"以体循环淤血为主要表现，主要症状包括：1.消化道症状: \n" + "2.劳力性呼吸困难:继发于左心衰的右心衰呼吸困难业已存在....",
    text16:"左心衰竭继发右心衰竭而形成的全心衰竭，因右心衰竭时右心排血量减少，因此以往的阵发性呼吸困难等肺淤血症状反而有所减轻。扩张型心肌病等同....",
    text17:"突发严重呼吸困难，呼吸频率常达30~50次/分，强迫坐位、面色灰白、发绀、大汗、烦躁，同时频繁咳嗽，咳粉红色泡沫状痰。极重者可因脑缺....",






  },
  NewsJBParticulars:{
text1:"肺动脉高压（pulmonary hypertension，PH）是由多种异源性疾病（病因）和不同发病机制所致肺血管结构或功能改变，引起肺血管阻力和肺动脉压力升高的临床和病理生理综合征，继而发展成右心衰竭甚至死亡。" +
  "PH是指海平面、静息状态下，经右心导管检查（right heart catheterization，RHC）测定的肺动脉平均压（mean pulmonary artery pressure, mPAP）≥ 25 mmHg（1 mmHg=0.133 kPa）。正常成年人静息状态下mPAP 为（14.0±3.3）mmHg，其上限不超过 20 mmHg。",
text2:"临床上将肺动脉高压(PH)分为 5大类：" +
  "1.动脉性PH（pulmonary arterial hypertension，PAH） " +
  "2.左心疾病所致的PH " +
  "3.肺部疾病和或低氧所致的PH " +
  "4.慢性血栓栓塞性PH（CTEPH）和或其他肺动脉阻塞性病变所致的PH " +
  "5.未明和或多因素所致的PH。",
    text3: "肺动脉高压的症状是非特异的，早期可无症状，随病情进展可有如下表现：" +
      "1.呼吸困难：最早出现，也最常见。表现为进行性活动后气短，病情严重的在休息时也可出现；" +
      "2.疲劳、乏力、运动耐量减低、晕厥、心绞痛或胸痛、咯血、声音嘶哑 " +
      "3.口唇、指尖、耳廓发绀，食欲缺乏、恶心、呕吐、上腹胀痛，双下肢、会阴、腰骶部水肿，胸腹水，神经系统症状等" +
      "4.某些类型肺动脉高压还会有原发病的症状，如结缔组织病相关性肺动脉高压可有脱发、光敏、口腔溃疡、关节炎等。",
    text4: "每百万成人中有~15-52例患者。更多的病例登记研究提示，患病率可能会更高。" +
      "肺动脉高压预后差，中位生存期仅2.8年；1、3和5年生存率分别为68%、48%和34%。",
    text5:'糖尿病(Diabetes Mellitus, DM)是一组由多病因引起以慢性高血糖为特征的代谢性疾病，是由于胰岛素分泌和(或)利用缺陷所引起。长期碳水化合物以及脂肪、蛋白质代谢紊乱可引起多系统损害导致眼、肾、神经、心脏、血管等组织器官慢性进行性病变、功能减退及衰竭;病情严重或应激时可发生急性严重代谢紊乱，如糖尿病酮症酸中毒(DKA)、高渗高血糖综合征。',
    text6:"糖尿病是常见病、多发病，是严重威胁人类健康的世界性公共卫生问题。目前在世界范围内，糖尿病患病率、发病率急剧上升。据国际糖尿病联盟（IDF）统计：2015 年全球糖尿病患病人数已达4.15亿。预计到2040年全球糖尿病患病总人数将达到 6.42 亿；2015年我国成人糖尿病人数量为1.096亿，居世界第一位。",
    text7:'糖尿病的分型采用WHO（1999年）的糖尿病病因学分型体系，根据病因学证据将糖尿病分为4种类型，即1型糖尿病（T1DM）、2 型糖尿病（T2DM）、特殊类型糖尿病和妊娠期糖尿病。',
    text8:'1.空腹血糖、随机血糖或OGTT2h血糖是诊断 糖尿病的主要依据，没有糖尿病典型临床症状 时必须重复检测以确认诊断。\n' +
      '2.在有严格质量控制的实验室，采用标准化检测 方法测定的 HbA1c可以作为糖尿病的补充诊断标准。\n' +
      '3.2011年世界卫生组织（WHO）建议在条件具备 的国家和地区采用糖化血红蛋白（HbA1c ）诊断糖尿 病，诊断切点为 HbA1c≥6.5%。\n' +
      '*OGTT为口服葡萄糖耐量试验；\n' +
      '*HbA1c为糖化血红蛋白 。',
    text9:'基本临床表现：\n' +
      '1.代谢紊乱症状群血糖升高后因渗透性利尿引起多尿，继而口渴多饮;外周组织对葡萄糖利\n' +
      '用障碍，脂肪分解增多、蛋白质代谢负平衡，渐见乏力、消瘦，儿童生长发育受阻;病人常有易饥、多食。\n' +
      '故糖尿病的临床表现常被描述为“三多一少”，即多尿、多饮、多食和体重减轻。可有皮肤瘙痒，尤其外阴瘙痒。血糖升高较快时可使眼房水、晶状体渗透压改变而引起屈光改变致视物模糊。许多病人无任何症状,仅于健康检查或因各种疾病就诊化验时发现高血糖。\n' +
      '2. 并发症和/或伴发病：急性严重代谢紊乱、感染性疾病、慢性并发症等 。',
    text10:"心力衰竭(heartfailure，HF)是各种心脏结构或功能性疾病导致心室充盈和(或)射血功能受损，心排血量不能满足机体组织代谢需要，以肺循环和(或)体循环淤血、器官、组织血液灌注不足为临床表现的一组综合征。主要表现为呼吸困难、体力活动受限和体液潴留。心功能不全(cardiac dysfunction)或心功能障碍理论上是一个更广泛的概念，伴有临床症状的心功能不全称之为心力衰竭(简称心衰)。",
     text11:"慢性心力衰竭(Chronicheartfailure，CHF)是心血管疾病的终末期表现和最主要的死因，是21世纪心血管领域的两大挑战之一。\n" +
  "据我国2003年的抽样调查，成人心衰患病率为0.9%；发达国家心衰患病率为1%~2%，每年发病率为05%~1%。随着年龄的增长，心衰患病率迅速增加，70岁以上人群患病率更上升至10%以上。心力衰竭病人4年死亡率达50%，严重心衰病人1年死亡率高达50%，而年龄校正的心衰死亡率亦呈上升趋势。尽管心力衰竭治疗有了很大进展，心衰病人死亡数仍在不断增加。",
        text12:"一、左心衰竭、右心衰竭和全心衰竭\n" +
          "左心衰竭由左心室代偿功能不全所致，以肺循环淤血为特征，临床上较为常见。\n" +
          "单纯的右心衰竭主要见于肺源性心脏病及某些先天性心脏病，以体循环淤血为主要表现。\n" +
          "左心衰竭后肺动脉压力增高，使右心负荷加重，右心衰竭继之出现，即为全心衰竭。\n" +
          "\n" +
          "二、急性和慢性心力衰竭\n" +
          "根据心衰发生的时间、速度、严重程度可分为慢性心衰和急性心衰。\n" +
          "急性心衰系因急性的严重心肌损害、心律失常或突然加重的心脏负荷，使心功能正常或处于代偿期的心脏在短时间内发生衰竭或慢性心衰急剧恶化。临床上以急性左心衰常见，表现为急性肺水肿或心源性休克。\n" +
          "慢性心衰有一个缓慢的发展过程，一般均有代偿性心脏扩大或肥厚及其他代偿机制的参与。\n" +
          "\n" +
          "三、射血分数降低性心衰(HFrEF)和射血分数保留性心衰(HFpEF)\n" +
          "对于心衰的描述主要基于左室射血分数(leftventricular eiection fractionLVEF)。\n" +
          "LVEF<40%者称为射血分数降低性心衰(HFwith reduced EFHFrEF)即传统概念中的收缩性心衰。LVEF≥50%的心衰称为射血分数保留性心衰(HFwith preserved EFHFpEF)，通常存在左室肥厚或左房增大等充盈压升高，舒张功能受损的表现，以前称为舒张性心衰。",
         text13:"一、心肌损害\n" +
           "1.原发性心肌损害；\n" +
           "2.继发性心肌损害； \n" +
           "二、心脏负荷过重：\n" +
           "1.压力负荷(后负荷)过重：见于高血压、肺动脉高压等左、右心室收缩期射血阻力增加的疾病；\n" +
           "2.容量负荷(前负荷)过重；\n" +
           "三、心室前负荷不足：二尖瓣狭窄、心脏压塞、限制性心肌病缩窄性心包炎等。",
    text14:"一、左心衰竭以肺循环淤血及心排血量降低为主要表现：\n" +
      "1.不同程度的呼吸困难：①劳力性呼吸困难:是左心衰竭最早出现的症状。②端坐呼吸：肺淤血达到一定程度时，病人不能平卧，呼吸更为困难。高枕卧位、半卧位甚至端坐时方可好转。③夜间阵发性呼吸困难：入睡后突然因憋气而惊醒，被迫取坐位，④急性肺水肿:是左心衰呼吸困难最严重的形式，重者可有哮鸣音称为“心源性哮喘”；\n" +
      "2.咳嗽、咳痰、咯血：开始常于夜间发生，坐位或立位时咳嗽可减轻，白色浆液性泡沫状痰为其特点； \n" +
      "3.乏力、疲倦、运动耐量减低、头晕、心慌等器官、组织灌注不足及代偿性心率加快所致的症状；\n" +
      "4.少尿及肾功能损害症状。",
     text15:"以体循环淤血为主要表现，主要症状包括：\n" +
  "1.消化道症状: \n" +
  "2.劳力性呼吸困难:继发于左心衰的右心衰呼吸困难也已存在。",
    text16:"左心衰竭继发右心衰竭而形成的全心衰竭，因右心衰竭时右心排血量减少，因此以往的阵发性呼吸困难等肺淤血症状反而有所减轻。扩张型心肌病等同时存在左、右心室衰竭者，肺淤血症状往往不严重，主要表现为左心衰竭心排血量减少的相关症状和体征。",
     text17:"突发严重呼吸困难，呼吸频率常达30~50次/分，强迫坐位、面色灰白、发绀、大汗、烦躁，同时频繁咳嗽，咳粉红色泡沫状痰。极重者可因脑缺氧而致神志模糊、心源性休克（持续性低血压，如皮肤湿冷、苍白和发绀，尿量显著减少，意识障碍等）。",


  },

  ourEnvironment: {
    text1: "工作区采用开放式布局，提供了宽敞的工作桌和舒适的工作椅。每个工作区都配备了现代化的办公设备，如电脑、显示器、电话等，以满足员工的工作需求。\n" +
      "办公区还设有共享空间，供员工休憩和交流。这个区域配备了舒适的沙发、茶水间和咖啡机，让员工可以在工作之余放松一下，与同事进行简短的交流。",
    text2: "我们的前台是我们办公区的门面和接待中心，旨在为员工和访客提供友好、专业的服务。\n" +
      "前台工作人员经过专业培训，具备良好的沟通能力和服务意识，能够热情接待来访者并提供必要的帮助和指引。无论是来访者还是员工，都可以在前台获得相关的信息和支持。",
    text3: "我们的会议室拥有现代化的设施和舒适的环境，以提供高效和顺畅的会议体验。以下是我们会议室的主要特点：\n" +
      "多功能空间：我们的会议室可根据需要进行调整和布置，适应不同类型的会议，如团队会议、客户会议、培训和演示等。",
    text4:"我们的休闲区是为员工提供放松和休息的场所，打造一个舒适、轻松和愉快的环境。休闲区设有舒适的沙发、柔软的座椅和宽敞的空间，供员工放松和休息。员工可以在这里休息片刻、阅读书籍、聊天或享受一杯咖啡。",
    text5:"我们的办公室是一个现代化、舒适和灵活的工作空间，旨在提供一个有利于员工创造力和效率的环境。们采用开放式办公室布局，以促进团队合作和沟通。这种布局消除了传统办公室中的固定隔间和墙壁，提供了一个开放的工作环境，让员工可以更轻松地互动和合作。"


  },
  news:{
    title1:"医疗领域成为AI竞争新战场",
    title2:"深圳构建“1+6+X”服务新格局助力医疗器械产业高质量发展",
    title3:"国家卫生健康委：促进优质医疗资源扩容和区域均衡布局 推进医药领域改革和创新发展",
    title4:"医疗器械板块全线拉升！",

    omit1:"据韩国《亚洲经济》7月27日援引彭博社报道称，随着生成型人工智能（AI）迅速发展，医疗领域也正在成为....",
    omit2:"近日，深圳市医疗器械产业服务站揭牌成立。深圳市市场监管局相关负责人表示，该服务站可视为深圳市市场监管局监管工作的延伸....",
    omit3:"上证报中国证券网讯 据国家卫生健康委7月24日消息，国家卫生健康委印发《深化医药卫生体制改革2023年下半年重点工作任务》....",
    omit4:"消息面上，昨日深圳工信局发布扩大救治类医疗器械和药品生产能力通知，将承担ECMO、呼吸机、制氧机....",


    text1:"据韩国《亚洲经济》7月27日援引彭博社报道称，随着生成型人工智能（AI）迅速发展，医疗领域也正在成为AI竞争的战场。继微软、谷歌之后，亚马逊也将在这一领域参战。亚马逊表示，将推出“AWS Health Scribe”服务，使用生成型AI和语音识别技术自动制作患者诊疗记录。这是一种自动识别医疗从业者和患者之间的对话内容，并实时抄录、概括和制作诊疗记录的工具。由于亚马逊的参战，与微软和谷歌在医疗领域形成了“AI三角竞争”态势。微软已于今年3月推出了诊疗记录应用程序“Dax Express”，谷歌也于今年1月推出了名为“Med-PaL”的AI聊天机器人，为医疗专家和患者的提问提供有用的答案。彭博社表示，在企业推动生成型AI商用化的过程中，医疗领域也将成为生成型AI的核心战场。",
    text2:"\n" +
      "近日，深圳市医疗器械产业服务站揭牌成立。深圳市市场监管局相关负责人表示，该服务站可视为深圳市市场监管局监管工作的延伸，给“正在赶路”或者“身处迷茫”的生物医药企业提供“加油补给”和对接资源信息，出实招打通助企纾困“最后一公里”，助力深圳市医疗器械企业在高质量发展的赛道上精准发力。\n" +
      "深圳医疗器械产业多年来规模保持快速增长，已经成为我国医疗器械产业高地。据深圳医疗器械行业协会不完全统计，目前深圳医疗器械企业超1800家。2022年，深圳市高端医疗器械产业增加值达到332亿元，同比增长12.4%，约占行业总产值的8%至10%，出口占比超过13%，居全国各大城市之首；亿元以上企业数居各城市之首，单个企业平均产值超过6000万元，保持在全国平均水平两倍以上；上市企业市值占全国总量近1/3，体量居全国首位。同时，海外市场正成为深圳医疗器械企业大展拳脚的新天地。以迈瑞为代表的深圳医疗器械企业，早已开启国际化布局且耕耘多年，在全球市场与国际巨头同台竞技，并获得亮眼成绩。\n" +
      "近年来，深圳的医疗器械产业高速发展，但同时也面临国际威胁挤压深圳产业发展空间、国内优势区域间竞争加剧、产业内部创新发展需要不断融合突破等多方面挑战。为更好规范行业发展，帮助医疗器械企业纾困解难，深圳市市场监管局通过监管执法、标准完善、服务前置等举措，持续发挥服务型监管对产业发展的促进作用，相继开展第一类医疗器械“高类低备”市场整治行动，组织全市执法力量对行业企业开展全方位摸底排查，查处一批典型案件，及时消除风险隐患，督促整改发现的问题，全面规范第一类医疗器械市场秩序；发布地方标准《医疗器械唯一标识数据接口规范》，该标准是全国首个市场监管、卫健、医保3部门联合编制的UDI（医疗器械统一标识）实施地方标准，具有重要先行示范意义；在医疗器械行业新增和修订产品认证目录，发挥标准的引领作用，提升产品和服务质量。\n" +
      "据悉，此次成立深圳市医疗器械产业服务站亦是措施之一。该站将以服务企业发展、服务行业发展、服务产业发展作为出发点和落脚点，构建“1+6+X”服务新格局，提供多元化服务功能，助力医疗器械研得科学、产得安全、管得严格、用得放心。相关工作由深圳市市场监管局下属许可审查中心专门成立部门负责。\n" +
      "“1+6+X”服务新格局中的“1”是指配置1套医疗器械注册人综合服务体系。“6”是配套6类重点服务措施：一是突出服务导向，建立健全服务对象建档机制；二是突出政策引领，建立健全行业政策精准推送机制；三是突出产业发展，建立健全研审联动靠前服务机制；四是突出规范化建设，建立健全定制化辅导机制；五是突出业务衔接，建立健全项目与市场监管部门联络服务机制；六是突出资源整合，建立健全产业政策、监管政策咨询支撑机制。“X”是指提供若干“一对一”特色衍生服务。\n" +
      "据介绍，深圳市将以医疗器械产业服务站的成立为契机，坚持管字为本，全方位提升许可服务水平，拓展审评检查资源，加强监管能力建设；坚持服字为重，切实发挥好服务企业“宣传员”“联络员”“指导员”作用，打通服务企业“最后一公里”；坚持实字为要，出创新于实践、寓创新于实招、见创新于实效，高标准推动服务站运行，高效能服务企业发展，高质量助力提升产业能级，力争打造广东省区域性医疗器械产业服务站“示范样本”。\n",

 text3:"上证报中国证券网讯 据国家卫生健康委7月24日消息，国家卫生健康委印发《深化医药卫生体制改革2023年下半年重点工作任务》（以下简称《任务》）。\n" +
   "　　《任务》明确，2023年下半年医改工作主要包括六个方面20条具体任务。\n" +
   "　　一是促进优质医疗资源扩容和区域均衡布局。推进国家医学中心和国家区域医疗中心设置建设，持续提升地市和县级医疗水平，加强社区和农村医疗卫生服务能力建设，完善促进分级诊疗的体制机制，促进中医药传承创新发展，推动“大病重病在本省就能解决，一般的病在市县解决，头疼脑热在乡镇、村里解决”。\n" +
   "　　二是深化以公益性为导向的公立医院改革。推进医疗服务价格改革和规范化管理，深化公立医院薪酬制度改革，加快推进公立医院高质量发展，规范民营医院发展，全面加强医药领域综合监管，形成风清气正的行业环境。\n" +
   "　　三是促进多层次医疗保障有序衔接。巩固健全全民基本医保，完善多层次医疗保障制度，深化多元复合式医保支付方式改革，最大化发挥各项制度效应，有效减轻群众看病就医负担。\n" +
   "　　四是推进医药领域改革和创新发展。支持药品研发创新，常态化开展药品和医用耗材集中带量采购，加强药品供应保障和质量监管，确保“供好药”“用好药”。\n" +
   "　　五是健全公共卫生体系。促进医防协同、医防融合，推进疾病预防控制体系改革，提升公共卫生服务能力，从制度完善、人才队伍建设、评价考核等多方面共同努力推动公共卫生体系建设和能力提升。深入开展健康中国行动和爱国卫生运动，持续提高群众健康素养。\n" +
   "　　六是发展壮大医疗卫生队伍。加强紧缺专业和高层次人才培养，加强以全科医生为重点的基层队伍建设，实施大学生乡村医生专项计划，深化基层薪酬、岗位设置等方面的改革，提高基层医务人员积极性，提高基层医疗卫生服务能力，切实把工作重点放在社区和农村。\n",

  text4:"消息面上，昨日深圳工信局发布扩大救治类医疗器械和药品生产能力通知，将承担ECMO、呼吸机、制氧机、经鼻高流量湿化治疗仪、抗COVID-19病毒药物等生产任务的企业列入白名单予以重点支持，全力协调保障白名单企业及其核心供应商企业连续生产。组织金融机构与白名单企业对接，全力满足企业融资需求。鼓励白名单企业扩大相关产线技术改造投资，对企业2022年12月1日到2023年3月31日期间购买的设备，按照不超过设备投资的30%予以资助，最高不超过3000万元。\n" +
    "从主流券商观点看，多数比较看好“常规医疗复苏”与“公共卫生防控需求”两条主线。一是过去需求被抑制的消费性医疗服务行业，眼科、口腔科、医美等；二是当城市地铁日客运量等指标回升后，预计医院门诊量和手术量恢复；三是在目前公共卫生防控政策持续优化的大背景下，公共卫生防控引发的增量需求持续释放。\n" +
    "财信证券最新研报指出，2023年医疗器械春暖花开，静待行业变革与复苏。此前国内由于出行限制，医疗终端需求减少，随着公共卫生防控政策的优化，财信认为既往因公共卫生防控压制的医疗需求将逐步回升，长期看国内医疗需求增长态势不改，院内手术量（如因公共卫生防控后延的择期手术）有望迎来回弹，院外消费医疗也将持续回温；本轮医疗基建正如火如荼在全国铺开，产能将陆续释放，专项建设、设备采购订单将逐渐兑现。同时，后公共卫生防控时代，全球加强公共卫生建设，也为国内医疗器械产品出海提供发展机遇，医疗器械行业国产替代、国际化进程持续加速。\n" +
    "医疗器械ETF基金经理万纯最新观点认为，接下来，市场进入年报、一季报行情期，高增板块或有望再次成为市场主线。医疗器械板块业绩确定性相对较高，未来值得期待。①医疗设备：贴息贷款、ICU配置等或迎一定业绩增量。②医疗耗材：高值耗材集采已经进入后半程，大多数国产化率高的品种集采过了，而且从政策趋势上看集采规则相对温和。另外，耗材里面不少和眼科、牙科、院内手术等疫后复苏有关，这块后续也会或将快速恢复。③体外诊断：生化集采符合预期，份额向头部国产平台公司集中。集采趋于理性的趋势没变，未来发光集采政策风险相对减少，国产替代有望加速。长期看，对医药板块而言，任何形式的进口替代都是医药板块未来三年最为重要的方向，而医疗器械板块则是其中最大的“金矿”之一。\n" +
    "借道行业代表ETF布局医疗器械板块\n" +
    "医疗器械ETF为目前A股规模最大的一只医疗器械行业ETF。该ETF追踪中证全指医疗器械指数，覆盖医疗设备、IVD、医疗耗材、医美四大板块，全面表征A股医疗器械行业发展。指数前十大权重分别为迈瑞医疗、爱美客、乐普医疗、万泰生物、金域医学、九安医疗、鱼跃医疗、奕瑞科技、欧普康视、新产业等龙头股，合计占比近46%（截至2022年底）。截至1月12日，经过前期持续行情回暖，标的指数PE值仍仅19.6倍，处指数发布以来较低分位数。\n" +
    "\n" +
    "对于普通的投资者而言，医疗器械种类繁杂且研究门槛较高，个股波动大，个人投资者研究难度较高，借道指数基金更省心，还可分散个股投资风险。场内用户可通过医疗器械ETF（159883），一手仅需70元左右，免缴印花税；场外用户可关注联接基金（A份额013415，C份额013416）。\n" +
    "\n"
  }




}

