<template>
  <div class="container-fluid bgC">
    <div class="row father">
      <div class="col-2 son p">
        <span  @click="goTabPage(1)">{{  $t('bottoms.who') }}</span>
        <span  @click="goTabPage(2)">{{ $t('bottoms.what') }}</span>
        <span  @click="goTabPage(3)">{{ $t('bottoms.how') }}</span>
      </div>

        <div class="col-2 son pad p">
          <span  @click="goTabPage(4)">{{ $t('bottoms.our') }}</span>
          <span  @click="goTabPage(5)">{{ $t('bottoms.news') }}</span>
          <span >{{ $t('bottoms.car') }}</span>
        </div>
        <div class="col-7 son padO">
          <span style="line-height: 25px">{{ $t('bottoms.companyAddress') }}</span>
          <div style="display: flex">
            <span>{{$t('bottoms.companyPhone') }}</span>
            <span style="margin-left: 80px">Email:service@huanbobiotech.com</span>


          </div>
        </div>
        <div class="col-1 son padO">
          <div @click="contactUs" class="relation">{{ $t('bottoms.relation') }}</div>
        </div>

    </div>
  </div>









<!--  <div class="container-fluid bgC">-->
<!--    <div class="row father">-->
<!--      <div class="col-2 son p">-->
<!--        <span  @click="goTabPage(1)"></span>-->
<!--        <span  @click="goTabPage(2)"></span>-->
<!--        <span  @click="goTabPage(3)"></span>-->
<!--      </div>-->
<!--      <div class="col-2 son pad p">-->
<!--        <span  @click="goTabPage(4)"></span>-->
<!--        <span  @click="goTabPage(5)"></span>-->
<!--        <span></span>-->
<!--      </div>-->
<!--      <div class="col-7 son padO">-->
<!--        <span></span>-->
<!--        <span></span>-->
<!--        <div style="display: flex">-->
<!--          <span></span>-->
<!--          <span style="margin-left: 100px"></span>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="col-1 son padO">-->
<!--        <div @click="contactUs" class="relation"></div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->





</template>
<script setup>
import router from '@/router'
// import { getCurrentInstance } from "vue";
// import { useI18n } from 'vue-i18n'
// const _this = getCurrentInstance().appContext.config.globalProperties
// 此处可打印_this查看是否存在$t方法 如果$t方法不存在
//检查一下i18n配置globalInjection: true是否发开如果打开了仍无效 查看下方代码
// console.log(_this.$t("bottom.relation"))

// const { $t } = createI18n()










const goTabPage = (type)=>{
  switch (type){
    case 1:
      router.push({ path: '/whoWeAre' })
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
      break
    case 2:
      router.push({ path: '/weOffer' })
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
      break
    case 3:
      router.push({ path: '/doIt' })
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
      break
    case 4:
      router.push({ path: '/peopleAndValue' })
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
      break
    case 5:
      router.push({ path: '/news' })
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
      break

  }
}

const  contactUs = ()=>{
  router.push({ path: '/Map', query:{num:99} })



}
</script>
<style lang="scss" scoped>

.bgC {
  width: 100%;
  background: url("@/assets/homeImg/bottomBgc.png") no-repeat;
  background-size: cover;
  height: 508px;
  color: #858691;
  z-index: 99;
  min-width: 1280px;
}

.father {
  height: 508px;
  padding: 80px 324px 60px 200px;
  font-weight: bold;
  box-sizing: border-box;

  .pad {
    padding-left: 30px;
  }

  .padO {
    padding-left: 90px;
  }

  .son {
    height: 140px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    span {
      font-size: 17px;
      font-weight: normal;
      color: #FFFFFF;
      display: inline-block;
    }
  }

  .relation {
    width: 90px;
    height: 32px;
    line-height: 32px;
    background: #1f6ed2;
    border-radius: 4px;
    font-size: 15px;
    text-align: center;
    font-family: 'Adobe Heiti Std';
    font-weight: normal;
    color: #edf1f7;
    cursor: pointer;
  }

}


.p{
  span{
    cursor: pointer;

  }
}

</style>
