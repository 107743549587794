<template>
<!--  <tabBar   :idIndex="3"></tabBar>-->

  <div class="main">


    <div class="logo">
  <img class="logoImg" src="@/assets/homeImg/jibingpu.png">
      <div class="logoTitle">{{$t('NewsJBList.tlt')}}</div>
    </div>


<div class="newBox">
  <div :style="{background: len < 1 ? '#eeeeee' :  '#007db4 ' }" class="btn" @click="btnCut(48.4)">
    <i :style="{color:len < 1 ? '#979797': '#fff' }" class="bi bi-chevron-left"></i>
  </div>
  <div class="newList">
    <div :style="{ transform: `translateX(${translate}rem)` }"  class="box_new">
      <div  class="b1">
        <img class="b1_img" src="@/assets/homeImg/JB1.png">
      </div>
      <div  class="b2">
       <div class="b2_n">
         <div class="title">
           {{$t('NewsJBList.title1')}}
         </div>
         <div class="text">
           {{$t("NewsJBList.text1")}}

         </div>
       </div>
   <div :style="{ transform: `translateX(${translate}px)` }"  class="b2_btbox">
     <div @click="goParticulars(0)" class="b2_button">
       查看详情
     </div>
   </div>
      </div>
    </div>
    <div :style="{ transform: `translateX(${translate}rem)` }"  class="box_new">
      <div class="b1">
        <img class="b1_img"  src="@/assets/homeImg/JB2.png">

      </div>
      <div  class="b2">
        <div class="b2_n">
          <div class="title">{{$t('NewsJBList.title2')}}</div>
          <div class="text">{{$t("NewsJBList.text2")}}   </div>

        </div>
        <div class="b2_btbox">
          <div @click="goParticulars(1)" class="b2_button">
            查看详情
          </div>
        </div>
      </div>
    </div>
    <div :style="{ transform: `translateX(${translate}rem)` }" class="box_new">
      <div class="b1">
        <img class="b1_img"  src="@/assets/homeImg/JB3.png">

      </div>
      <div  class="b2">
        <div class="b2_n">
          <div class="title">{{$t('NewsJBList.title3')}}</div>
          <div class="text">{{$t("NewsJBList.text3")}}   </div>
        </div>
        <div class="b2_btbox">
          <div @click="goParticulars(2)" class="b2_button">
            查看详情
          </div>
        </div>
      </div>
    </div>
    <div :style="{ transform: `translateX(${translate}rem)` }"   class="box_new" >
      <div class="b1">
        <img class="b1_img"  src="@/assets/homeImg/JB1.png">

      </div>
      <div  class="b2">
        <div class="b2_n">
          <div style="font-size: 23px;" class="title">{{$t('NewsJBList.title4')}}</div>
          <div class="text">{{$t("NewsJBList.text4")}}   </div>
        </div>
        <div class="b2_btbox">
          <div @click="goParticulars(3)" class="b2_button">
            查看详情
          </div>
        </div>
      </div>
    </div>
    <div :style="{ transform: `translateX(${translate}rem)` }"   class="box_new">
      <div class="b1">
        <img class="b1_img"  src="../../assets/JbImg/2.png">

      </div>
      <div  class="b2">
        <div class="b2_n">
          <div class="title">{{$t('NewsJBList.title5')}}</div>
          <div class="text">{{$t("NewsJBList.text5")}}   </div>
        </div>
        <div class="b2_btbox">
          <div @click="goParticulars(4)" class="b2_button">
            查看详情
          </div>
        </div>
      </div>
    </div>

    <div :style="{ transform: `translateX(${translate}rem)` }"   class="box_new">
      <div class="b1">
        <img class="b1_img"  src="../../assets/JbImg/11.png">

      </div>
      <div  class="b2">
        <div class="b2_n">
          <div class="title">{{$t('NewsJBList.title6')}}</div>
          <div class="text">{{$t("NewsJBList.text6")}}   </div>
        </div>
        <div class="b2_btbox">
          <div @click="goParticulars(5)" class="b2_button">
            查看详情
          </div>
        </div>
      </div>
    </div>

    <div :style="{ transform: `translateX(${translate}rem)` }"   class="box_new">
      <div class="b1">
        <img class="b1_img"  src="../../assets/JbImg/10.png">

      </div>
      <div  class="b2">
        <div class="b2_n">
          <div class="title">{{$t('NewsJBList.title7')}}</div>
          <div class="text">{{$t("NewsJBList.text7")}}   </div>
        </div>
        <div class="b2_btbox">
          <div @click="goParticulars(6)" class="b2_button">
            查看详情
          </div>
        </div>
      </div>
    </div>

    <div :style="{ transform: `translateX(${translate}rem)` }"   class="box_new">
      <div class="b1">
        <img class="b1_img"  src="../../assets/JbImg/9.png">

      </div>
      <div  class="b2">
        <div class="b2_n">
          <div class="title">{{$t('NewsJBList.title8')}}</div>
          <div class="text">{{$t("NewsJBList.text8")}}   </div>
        </div>
        <div class="b2_btbox">
          <div @click="goParticulars(7)" class="b2_button">
            查看详情
          </div>
        </div>
      </div>
    </div>

    <div :style="{ transform: `translateX(${translate}rem)` }"   class="box_new">
      <div class="b1">
        <img class="b1_img"  src="../../assets/JbImg/8.png">

      </div>
      <div  class="b2">
        <div class="b2_n">
          <div class="title">{{$t('NewsJBList.title9')}}</div>
          <div class="text">{{$t("NewsJBList.text9")}}   </div>
        </div>
        <div class="b2_btbox">
          <div @click="goParticulars(8)" class="b2_button">
            查看详情
          </div>
        </div>
      </div>
    </div>



    <div :style="{ transform: `translateX(${translate}rem)` }"   class="box_new">
      <div class="b1">
        <img class="b1_img"  src="../../assets/JbImg/1.png">

      </div>
      <div  class="b2">
        <div class="b2_n">
          <div class="title">{{$t('NewsJBList.title10')}}</div>
          <div class="text">{{$t("NewsJBList.text10")}}   </div>
        </div>
        <div class="b2_btbox">
          <div @click="goParticulars(9)" class="b2_button">
            查看详情
          </div>
        </div>
      </div>
    </div>

    <div :style="{ transform: `translateX(${translate}rem)` }"   class="box_new">
      <div class="b1">
        <img class="b1_img"  src="../../assets/JbImg/6.png">

      </div>
      <div  class="b2">
        <div class="b2_n">
          <div class="title">{{$t('NewsJBList.title11')}}</div>
          <div class="text">{{$t("NewsJBList.text11")}}   </div>
        </div>
        <div class="b2_btbox">
          <div @click="goParticulars(10)" class="b2_button">
            查看详情
          </div>
        </div>
      </div>
    </div>

    <div :style="{ transform: `translateX(${translate}rem)` }"   class="box_new">
      <div class="b1">
        <img class="b1_img"  src="../../assets/JbImg/5.png">

      </div>
      <div  class="b2">
        <div class="b2_n">
          <div class="title">{{$t('NewsJBList.title12')}}</div>
          <div class="text">{{$t("NewsJBList.text12")}}   </div>
        </div>
        <div class="b2_btbox">
          <div @click="goParticulars(11)" class="b2_button">
            查看详情
          </div>
        </div>
      </div>
    </div>

    <div :style="{ transform: `translateX(${translate}rem)` }"   class="box_new">
      <div class="b1">
        <img class="b1_img"  src="../../assets/JbImg/7.png">

      </div>
      <div  class="b2">
        <div class="b2_n">
          <div class="title">{{$t('NewsJBList.title13')}}</div>
          <div class="text">{{$t("NewsJBList.text13")}}   </div>
        </div>
        <div class="b2_btbox">
          <div @click="goParticulars(12)" class="b2_button">
            查看详情
          </div>
        </div>
      </div>
    </div>

    <div :style="{ transform: `translateX(${translate}rem)` }"   class="box_new">
      <div class="b1">
        <img class="b1_img"  src="../../assets/JbImg/4.png">

      </div>
      <div  class="b2">
        <div class="b2_n">
          <div class="title">{{$t('NewsJBList.title14')}}</div>
          <div class="text">{{$t("NewsJBList.text14")}}   </div>
        </div>
        <div class="b2_btbox">
          <div @click="goParticulars(13)" class="b2_button">
            查看详情
          </div>
        </div>
      </div>
    </div>

    <div :style="{ transform: `translateX(${translate}rem)` }"   class="box_new">
      <div class="b1">
        <img class="b1_img"  src="../../assets/JbImg/3.png">

      </div>
      <div  class="b2">
        <div class="b2_n">
          <div class="title">{{$t('NewsJBList.title15')}}</div>
          <div class="text">{{$t("NewsJBList.text15")}}   </div>
        </div>
        <div class="b2_btbox">
          <div @click="goParticulars(14)" class="b2_button">
            查看详情
          </div>
        </div>
      </div>
    </div>

    <div :style="{ transform: `translateX(${translate}rem)` }"   class="box_new">
      <div class="b1">
        <img class="b1_img"  src="../../assets/JbImg/12.png">

      </div>
      <div  class="b2">
        <div class="b2_n">
          <div class="title">{{$t('NewsJBList.title16')}}</div>
          <div class="text">{{$t("NewsJBList.text16")}}   </div>
        </div>
        <div class="b2_btbox">
          <div @click="goParticulars(15)" class="b2_button">
            查看详情
          </div>
        </div>
      </div>
    </div>

    <div :style="{ transform: `translateX(${translate}rem)` }"   class="box_new">
      <div class="b1">
        <img class="b1_img"  src="../../assets/JbImg/13.png">

      </div>
      <div  class="b2">
        <div class="b2_n">
          <div class="title">{{$t('NewsJBList.title17')}}</div>
          <div class="text">{{$t("NewsJBList.text17")}}   </div>
        </div>
        <div class="b2_btbox">
          <div @click="goParticulars(16)" class="b2_button">
            查看详情
          </div>
        </div>
      </div>
    </div>





  </div>


  <div :style="{background:len > 14 ?  '#eeeeee' : '#007db4' }" class="btn" @click="btnCut(-48.4)">
    <i :style="{color:len > 14 ? '#979797': '#fff' }"  class="bi bi-chevron-right"></i>
  </div>

</div>

  </div>
</template>
<script setup>
// import tabBar from '@/components/tabBar/tabIndex.vue'
import { ref } from 'vue'
// import router from '@/router'
import { defineProps  } from 'vue'
import router from '@/router'

const props =defineProps({
  NavigationBarIndex:{
    type:Number
  }

})




const goParticulars = (index) => {
  router.push({
    path: '/Particularst',
    query: {
      name: "jb",
      ide: index,
      navIde:3,
      NavigationBarIndex:props.NavigationBarIndex ? props.NavigationBarIndex : 2
    }
  })

}





const translate = ref(0)

const len = ref(0)

const btnCut = (num)=>{
if (num > 0 && len.value >= 1 ){
  len.value--

  translate.value += num - 1.7

}
if (num<0 && len.value<=14) {


  len.value++
  translate.value +=  num + 1.7

}


}



</script>
<style lang="scss" scoped>

.gor{
}
.right{
  transform: translateX(480px);
}

.main {
  width: 100%;
  height: 100vh;
  background: url("@/assets/homeImg/jibingBgc.png") no-repeat;
  background-size: cover;
overflow: hidden;
  .logo{
    //width: 100%;
    margin-top: 140px;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

.logoImg{
  width: 75px;
  height: 74px;

}

  .logoTitle{
    //width: 164px;
    height: 40px;
    font-size: 42Px;
    font-weight: normal;
    color: #FFFFFF;
    margin-bottom: 60px;
  }

  .introduceText{
    width: 1385px;
    font-size: 20Px;
    font-weight: normal;
    color: #FFFFFF;
    margin: 31px auto 35px;
    line-height: 36px;
    text-align: justify;
    word-wrap: break-word;
    word-break: break-all;
  }

  .newBox{
    width:1603px;
    height: 491px;
     margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .newList{
    width: 1400px;
    height: 100%;

    margin: 0 auto;
    display: flex;
    overflow: hidden;
    border-radius: 4px;
  }
  .box_new{
    width:45rem;
    height: 491px;
    background: #FFFFFF;
    border-radius: 4px;
    flex-shrink: 0;
    margin-right: 17px;
    transition: 1s all;
    text-overflow:ellipsis;

    .b1{
      height: 50%;
      width: 100%;
      .b1_img{
        width: 100%;
        height: 100%;
      }
    }
    .b2{
      height: 50%;
      width: 100%;
      background: #d4ebf5;
      border-radius: 0 0 4px 4px;

     .b2_n{
       width: 100%;
       height: 159px;
       border-bottom: 1px solid #c9dee8;
       overflow: hidden;
       padding: 0 24px;

       .title{
         font-size: 24px;
         font-weight: normal;
         color: #000000;
         margin: 18px auto 12px;
         line-height: 28px;
         text-align: justify;
         display: flex;
         justify-content: center;
         word-wrap: break-word;
         word-break: break-all;
       }
       .text{
       text-align: justify;
         font-size: 16px;
         text-indent: 32px;
         line-height: 28px;
         font-weight: normal;
         color: #4A525E;
         overflow: hidden;
         -webkit-box-orient: vertical; /* 必须结合的属性 ，设置或检索伸缩盒对象的子元素的排列方式 。*/
         text-overflow: ellipsis; /* 可以用来多行文本的情况下，用省略号“…”隐藏超出范围的文本 。*/
         -webkit-line-clamp: 2;
         word-wrap: break-word;
         word-break: break-all;
       }
     }
      .b2_btbox{
        position: relative;
        height: 86.5px

      }

      .b2_button{
        width: 194px;
        height: 46px;
        border-radius: 4px;
        color: #FFFFFF;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        line-height: 46px;
        font-size: 16Px;
        background: #2eaee5;
        cursor: pointer;

      }

    }
  }
  .btn{
    width: 55px;
    height: 55px;
    border-radius: 360px;
    background: #1f6ed2;
    i{
      text-align: center;
      font-size: 16px;
      line-height: 45px ;
      color: #FFFFFF;
    }
  }


}
</style>
