// en.js
export default {
  common: {
    more: 'more'
  },

  navBar: {
    companyName: 'HuanBo BioTech',
    Home: 'Home',
    who: 'Who We Are',
    what: 'What We Offer',
    How: 'How We Do It',
    People: 'People & Value',
    News: 'News & Views',
    callWe: 'contact'
  },
  bottoms: {
    who: 'Who We Are',
    what: 'What We Offer',
    how: 'How We Do It',
    our: 'Our People & Values',
    news: 'News & Views',
    car: 'Careers',
    companyName: 'companyName: HuanBo BioTech',
    companyAddress: 'Address:Room 1701, 17 / F, Block B, Qingyun Building, 266 Puyun Road',
    companyPhone: 'Hotline: 15050560903',
    companyMail: 'EMail: service@huanbobiotech.com',
    relation: 'contact us'
  },
  homePage: {
    companyInfoTitle: 'Company Info',
    companyInfoTxt:
      '京寰柏生物科技有限公司成立于2017年，是一家专注于心血管生物医药开发的公司，公司服务涵盖医药临床前研究的全过程，主要包括药物开发、药理学研究及血清标记物ELISA试剂盒开发。目前，公司已拥有一支具有丰富经验、熟练新药研发的核心技术团队，设有生物安全Ⅱ级细胞房......',
    homeMidOne: 'Global Specialist',
    homeMidTwo: 'Coverage of Users',
    homeMidThree: 'Research Team',
    disease: 'Disease Science Popularization',
    diseaseInfo:
      '京寰柏生物科技有限公司成立于2017年，是一家专注于心血管生物医药开发的公司，公司服务涵盖医药临床前研究的全过程，主要包括药物开发、药理学研究及血清标记物ELISA试剂盒开发。目前，公司已拥有一支具有丰富经验、熟练新药研发的核心技术团队，设有生物安全Ⅱ级细胞房......',
    ourTeam: 'Our Team',
    callWe: 'Contact Us',
    answers: 'Professional Service Team Answers',
    quick: 'Quick understanding of service content',
    solutions: 'Provide suitable solutions',
    inputCompanyName: 'Please enter the company name',
    inputName: 'Please enter a name',
    inputCall: 'Please enter contact information',
    inputSev: 'Please enter service requirements',
    submit: 'Confirm submission',
    lookMore: 'more',
    infoCompanyName: 'Nanjing Huanbai Biotechnology Co., Ltd',
    NewCenter: 'News Center',
    detail: 'detail'
  },
  whoWeAre: {
    who: 'Who We Are',
    introduce:"Founded in 2017, Nanjing Huanbai Biotechnology Co., Ltd. is a company focusing on the development of cardiovascular biomedicine. The company's services cover the whole process of medical preclinical research, including drug development, pharmacological research and serum marker ELISA kit development. At present, the company....."
    ,introduce2:"Nanjing Humanise Biotechnology Co., Ltd. was established in 2017 and is a company focused on cardiovascular biopharmaceutical development. The company provides services covering the entire process of preclinical drug research, including drug development, pharmacological research....."
    , assistant: 'Unlocking the molecular compounds for our patients',
    title:"Nanjing Huanbo Biotechnology Co., Ltd.",
  t:"Who We Are",
    text:"Company Introduction",
    b1:"multiple",
    b2:"view details",
    x1:"global patents",
    x2:"target audience coverage",
    x3:"research team",
    b3:"tens of millions",

  },
  weOffer: {
    offer: 'What We Offer',
    offerText1:"Orphan diseases are the common diseases among rare disease, as their prevalence is increasing and they are being recognized over time." +
     +"World-class medicines are the pipelines of our products." +

      "Mechanistic researches are the final pathway to identify the potential of treatment beginning right here."
  },
  howWeDoIt: {
    How: 'How We Do It',
    context:"Using our breakthroughs in screening platforms, we select the target proteins which involve in the development of orphan and chronic metabolic disease." +
      +
      "Our cutting-edge methods enable molecular-compounds discovery to break free of those diseases." +
     +
      "High-throughput experiments are creating the world’s most diverse small molecules, allowing us to demonstrate protein structure, biological activity, and organ distribution to build the new generation of medicines."


  },
  peopleAndValue: {
    people: 'Our Mission',
    team: 'Our Mission',
    companyAddress: 'Address of company:',
    partner: 'Partner with us',
    content:
      "To bring hopes to all patient with orphan and chronic metabolic disease." +
      +
      "Billions of patients are battling against those disease, waiting for a effective treatment, waiting for the truth of a dream deliver them to a normal, colorful life." +
      "At Huanbo, we never make our patients hopeless."
  },
  newsAndValue: {
    news: 'News & Views'
  },

  NewsJBList:{
    tlt:"Disease Popularization",
    topTitle:"Countries or regions have different criteria for defining rare diseases and the United States proposed a double definition in the Orphan Drug Act of 1983: the number of patients in the United States. Two hundred thousand; The number of patients is greater than 200,000, but the sales of drugs developed for the disease are not enough to compensate for the research and development",
    title1:"What is pulmonary arterial hypertension?",
    title2:"Pulmonary arterial hypertension is classified into several types?",
    title3:'What impact does pulmonary arterial hypertension have on the body?',
    title4:'How many patients are currently suffering from pulmonary arterial hypertension, and can they be cured?',
    title5:'What is diabetes?',
    title6:'The prevalence of diabetes',
    title7:'The classification of diabetes.',
    title8:'The main diagnostic criteria for diabetes',
    title9:'The main symptoms of diabetes',
    title10:"What is heart failure?",
    title11:"What is the incidence rate of heart failure?",
    title12:"The main types of heart failure are what?",
    title13:"The basic causes of heart failure are what?",
    title14:"What are the main symptoms of heart failure?",
    title15:"What are the main symptoms of right heart failure?",
    title16:"The main symptoms of congestive heart failure are what?",
   title17:"The main symptoms of acute heart failure are what?",

    text1:"Pulmonary hypertension (PH) is caused by various heterogeneous diseases (etiology) a.....",
text2:"In clinical practice, pulmonary hypertension (PH) is classified into five major groups. Grou.....",
    text3:"As the condition progresses, the following ...." ,
    text4:"There are approximately 15-52 cases of pati.....",
    text5:'Diabetes Mellitus (DM) is a group of metabolic disorders characterized by chronic hyperglycemia caused by multiple etiologies, resul.....',
    text6:"Diabetes is a common and prevalent disease, representing a significant global public health problem that poses a serious threat to  human .....",
    text7:'Diabetes is classified according to the World Health Organizations (WHO) 1999 classification system based on etiology Based on etiological evidence.....',
    text8:"The main diagnostic criteria for diabetes include fasting blood glucose, random blood glucose .....",
     text9:'Basic clinical manifestations: 1. Metabolic disorder symptom cluster, polyuria caused by osmotic diuresis following .....',
    text10:"heartfailure (HF) is a variety of structural or functional disorders of the heart that lead to impaired ventricular filling and/or ejection function.....",
    text11:"Chronic heart failure (CHF) is the terminal manifestation and the leading cause of.....",
    text12:" Left heart failure, right heart failure, and biventricular heart failure. Left hear.....",

    text13:"1. Primary myocardial injury; 2. Secondary myocardial injury.Cardiac overload: Excessive pressure.....",
    text14:"Left heart failure is primarily characterized by pulmonary congestion and reduced.....",
    text15:"The main manifestation is systemic congestion, with the main symptoms including:.....",
    text16:"Left heart failure progresses to biventricular heart failure due to the development of secondary....",
    text17:"Sudden severe dyspnea, with a respiratory rate often reaching 30-50 breaths per minute.....",
  },
  NewsJBParticulars:{
    text1:'Pulmonary hypertension (PH) is a clinical and pathophysiological syndrome characterized by changes in the structure or function of the pulmonary vasculature caused by various heterogeneous diseases (etiologies) and different pathogenic mechanisms, resulting in increased pulmonary vascular resistance and elevated pulmonary artery pressure. It can progress to right heart failure and even death. PH is defined as a mean pulmonary artery pressure (mPAP) of ≥ 25 mmHg, as measured by right heart catheterization (RHC) at sea level and at rest. The normal mPAP for adults at rest is (14.0±3.3) mmHg, with an upper limit not exceeding 20 mmHg.',
    text2:'In clinical practice, pulmonary hypertension (PH) is classified into five major groups: 1. Pulmonary arterial hypertension (PAH); 2. PH due to left heart diseases; 3. PH due to lung diseases and/or hypoxia; 4. Chronic thromboembolic pulmonary hypertension (CTEPH) and/or PH due to other pulmonary artery obstructions; 5. PH of unclear etiology and/or PH caused by multiple factors.',
    text3:`The symptoms of pulmonary hypertension are non-specific, and in the early stages, there may be no symptoms. As the condition progresses, the following manifestations may occur:

    Dyspnea: The earliest and most common symptom. It manifests as progressive shortness of breath after exertion, and in severe cases, it may occur even at rest.

    Fatigue, weakness, reduced exercise tolerance, dizziness, angina or chest pain, hemoptysis, hoarseness of voice.

    Cyanosis of lips, fingertips, and earlobes, loss of appetite, nausea, vomiting, upper abdominal distension, bilateral lower limb, perineum, lumbar and sacral edema, pleural and abdominal effusions, neurological symptoms, etc.

    Some types of pulmonary hypertension may also present symptoms related to the underlying disease. For example, connective tissue disease-associated pulmonary hypertension may be associated with symptoms such as hair loss, photosensitivity, oral ulcers, and arthritis.
    `,
    text4:'There are approximately 15-52 cases of patients per million adults. However, more extensive registry studies suggest that the prevalence may be even higher. Pulmonary arterial hypertension has a poor prognosis, with a median survival of only 2.8 years; the 1-year, 3-year, and 5-year survival rates are 68%, 48%, and 34%, respectively.',
    text5:'',
    text6:'',
    text7:'',
    text8:'',
    text9:'',
    text10:'',
    text11:'',
    text12:'',
    text13:'',
    text14:'',
    text15:'',
    text16:'',
    text17:'',

  }

}
