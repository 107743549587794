<template>
<!--  <tabBar v-if="flag" :idIndex="5"></tabBar>-->
  <div class="main">
    <div class="new-h">
      <div class="title">
        <div class="verticalRightText">
          NEWS
        </div>
        <div class="verticaltitle">新闻资讯</div>
        <div class="verticalLine"></div>
      </div>
    </div>
    <div class="Newsbox">
      <div class="Newsbox_img animate__animated"  :class="esMate()&&['animate__fadeInTopLeft']">
        <img src="@/assets/homeImg/newsImg.png">
      </div>
      <div class="Newsbox_Text animate__animated" :class="esMate()&&['animate__fadeInBottomRight ']">
        <div class="newsBox">
          <div class="headTitle">
            <span> {{$t("news.title1")}} </span>
            <span>05-19</span>
          </div>
          <div class="introduceText">
            <span> {{$t("news.omit1")}} </span>
            <span @click="golist">详细</span>
          </div>
        </div>
        <div class="newsBox">
          <div class="headTitle" >
            <span>{{$t("news.title4")}}</span>
            <span>05-19</span>
          </div>
          <div class="introduceText">
            <span>{{$t("news.omit4")}}</span>
            <span @click="golist">详细</span>
          </div>
        </div>

      </div>
      <div class="NewsDialog animate__animated" :class="esMate()&&['animate__fadeInRight']">
        <div class="gb">
          <div style="color: #FFFFFF;font-size: 2.5rem;">06 <span style="font-size:1.4rem;color: #FFFFFF;">日</span>
          </div>
          <div style="color: #FFFFFF;font-size: 2.5rem;margin:3.3rem 0 ">/</div>
          <div style="color: #FFFFFF;font-size: 2.5rem;">6 <span style="font-size: 1.4rem;color: #FFFFFF;">月</span>
          </div>


        </div>
        <div class="NewsDialogBox">
          <div class="NewsDialogBoxTile">{{$t("news.title3")}}</div>
          <div class="NewsDialogBoxintroduce">
            {{$t("news.omit3")}}
          </div>
<div style="display: flex;justify-content: flex-end;width: 100%">          <div class="NewsDialogBoxintroduceButton" @click="golist">详细</div></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
// import tabBar from '@/components/tabBar/tabIndex.vue'
import router from '@/router'
import { defineProps, toRefs } from 'vue'
import { useRoute } from 'vue-router'

const props = defineProps({
  flag: {
    type: Boolean,
    default: true,
  },
  scrIndexActive:{
    type: Number,

  },
  NavigationBarIndex:{
    type: Number,

  }

})
// eslint-disable-next-line no-unused-vars
const {scrIndexActive,flag,NavigationBarIndex } = toRefs(props)

const golist = () => {
  router.push({
    path: '/AListOfDetails',
    query: {
      name: 'xw',
      ide: 0,
      navIde:5,
      NavigationBarIndex:NavigationBarIndex.value ? NavigationBarIndex.value : 2
    }
  })
}




// const scrIndexActive = ref()

//   scrIndexActive.value = toRefs(props).scrIndex
// console.log(scrIndexActive.value,111)


const Route = useRoute() //获取到值
const  esMate = ()=>{
  if (scrIndexActive.value===5||scrIndexActive.value===0){
    return true
  }
  if (Route.query.num){
    return true
  }
}

</script>
<style lang="scss" scoped>
.main {
  width: 100%;

  height: 100vh;
  background: url("@/assets/homeImg/newws.png");
  position: relative;
  min-width: 1280px;

  .new-h {
    height: 609px;
    width: 100px;
    margin-left: 148px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: flex-end;

  }

  .title {
    width: 100px;
    height: 495px;
    display: flex;
    justify-content: space-between;

    .verticalRightText {
      width: 18px;
      word-wrap: break-word; /*英文的时候需要加上这句，自动换行*/
      font-size: 18px;
      color: #FFFFFF;
      writing-mode: vertical-rl

    }

    .verticaltitle {
      width: 28px;
      word-wrap: break-word; /*英文的时候需要加上这句，自动换行*/
      font-size: 29px;
      font-weight: normal;
      color: #FFFFFF;
      line-height: 32px
    }

    .verticalLine {
      width: 2px;
      height: 209px;
    }
  }

  .Newsbox {
    width: 1339px;
    height: 609px;
    position: absolute;
    top: 50%;
    left: 51%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: flex-end;

    .Newsbox_img {
      width: 680px;
      height: 495px;


      img {
        width: 100%;
        height: 100%;
        border-radius: 4px;

      }
    }

    .Newsbox_Text {
      width: 680px;
      height: 495px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;

      .newsBox {
        height: 140px;
        width: 607px;
        padding: 0 3.8rem 0 2.2rem;
        border-bottom: 1px solid rgba(255, 255, 255, .5);

        .headTitle {
          width: 100%;
          display: flex;
          justify-content: space-between;
          margin-top: 2.4rem;
          span:nth-child(1) {
            font-size: 20px;
            font-weight: normal;
            color: #FFFFFF;
          }

          span:nth-child(2) {
            font-size: 18px;
            font-weight: normal;
            color: #FFFFFF;
          }

        }

        .introduceText {
          width: 100%;
          display: flex;
          justify-content: space-between;
          margin: 24px 0;
            line-height: 32px;
          text-indent:32px ;

          span:nth-child(1) {
            width: 458px;
            height: 39px;
            font-size: 17px;
            font-weight: normal;
            color: #FFFFFF;
          }

          span:nth-child(2) {
            height: 39px;
            display: inline-block;
            font-size: 15px;
            font-weight: normal;
            color: #FFFFFF;
            cursor: pointer;
            line-height: 39px;
          }
        }
      }
    }

    .NewsDialog {
      width: 742px;
      height: 279px;
      background: #FFFFFF;
      position: absolute;
      right: 0;
      top: 0;
      border-radius: 4px;


      .gb {
        position: absolute;
        left: 46px;
        top: -10px;
        width: 66px;
        height: 218px;
        background: #00A2EA;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      .NewsDialogBox {
        height: 196px;
        width: 607px;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: 50px;
        transform: translate(-50%, -50%);
        padding: 0 20px 0 40px;
        .NewsDialogBoxTile {
          font-size: 20px;
          font-weight: normal;
          color: #202137;
          line-height: 30px;
        }

        .NewsDialogBoxintroduce {
          width: 523px;
          font-size: 17px;
          font-weight: normal;
          color: #787A87;
          margin: 23px 0 39px 0;
          line-height: 32px;
          text-indent:32px ;
        }

        .NewsDialogBoxintroduceButton {
          width: 122px;
          height: 40px;
          background: #00A2EA;
          border-radius: 4px;
          text-align: center;
          line-height: 40px;
          font-size: 15px;
          font-weight: normal;
          color: #FFFFFF;
          margin-left: auto;
          cursor: pointer;
        }
      }
    }

  }

}


</style>
