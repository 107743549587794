<template>
    <tabBar :idIndex="0"></tabBar>
  <div id="container" class=" box">
  <div style="height: 100vh;overflow: hidden">
    <div ref="box1"  :style="{transform: `translateY(${translate}vh)`}" :class="scrIndexActive === 0&& [ 'animate__fadeInDown']" class="box1" @wheel="scroIndex($event,0)">
      <!-- 轮播 -->
      <Banner></Banner>
    </div>
    <div ref="box2" :style="{transform: `translateY(${translate}vh)`}" class="box2  " @wheel="scroIndex($event,1)">
      <CompanyProfile :scrIndexActive="scrIndexActive" :NavigationBarIndex="1" ></CompanyProfile>
    </div>
    <div ref="box3"  :style="{transform: `translateY(${translate}vh)`}" class="box3  dyx " @wheel="scroIndex($event,2)">
      <OpularDisease  :NavigationBarIndex="1"></OpularDisease>
    </div>
    <div id="myElement"  :style="{transform: `translateY(${translate}vh)`}" ref="box4" class="box4" @wheel="scroIndex($event,3)">
      <CompanyLandscape :scrIndexActive="scrIndexActive"></CompanyLandscape>
    </div>
    <div ref="box5"  :style="{transform: `translateY(${translate}vh)`}" class="box5" @wheel="scroIndex($event,4)">

      <Map :flag="false"  :scrIndexActive="scrIndexActive"  ></Map>

    </div>

    <div ref="box6"  :style="{transform: `translateY(${translate}vh)`}" class="box6" @wheel="scroIndex($event,5)">
      <News :NavigationBarIndex="1" :scrIndexActive="scrIndexActive" ></News>
      <FootNavigation></FootNavigation>

    </div>


  </div>

    <div class="schedule">
      <div :class="scrIndexActive  ===0? 'actives':''" @click="go(0)">   <i></i></div>
      <div :class="scrIndexActive  ===1? 'actives':''" @click="go(1)">   <i></i></div>
      <div :class="scrIndexActive  ===2? 'actives':''" @click="go(2)">   <i></i></div>
      <div :class="scrIndexActive  ===3? 'actives':''" @click="go(3)">   <i></i></div>
      <div :class="scrIndexActive  ===4? 'actives':''" @click="go(4)">   <i></i></div>
      <div :class="scrIndexActive  ===5 || scrIndexActive===6 ? 'actives':''" @click="go(5)"><i></i></div>
    </div>

  </div>

</template>
<script setup>
import tabBar from '@/components/tabBar/tabIndex.vue'
import {  ref } from 'vue'
import CompanyProfile from '@/views/Page/CompanyProfile.vue'
import Banner from '@/views/Page/banner.vue'
import OpularDisease from '@/views/Page/OpularDisease.vue'
import CompanyLandscape from '@/views/Page/CompanyLandscape.vue'
import Map from '@/views/Page/Map.vue'
import News from '@/views/Page/News.vue'
import FootNavigation from '@/components/bottom/footNavigation.vue'
// 监听窗口大小改变的事件

const box1 = ref(null)
const box2 = ref(null)
const box3 = ref(null)
const box4 = ref(null)
const box5 = ref(null)
const box6 = ref(null)








const translate = ref(0)
const timer = ref(null)
const scrIndexActive = ref(0)

const scroIndex = (e, index) => {
const box6ScrTop =  ref(box6.value.scrollTop)

  if (index === 5 && box6ScrTop.value !== 0) return

  let val =e.deltaY > 0 ?  index + 1 > 5 ? 5 : index + 1 : index - 1 === -1 ? 0 : index - 1




  scrIndexActive.value = val
  if (timer.value) return

  timer.value = setTimeout(()=>{
    console.log( scrIndexActive.value,"当前位置")
   if (e.deltaY > 0) {

     if (translate.value - 100 === -600) {
       translate.value += 0
     }else {
       translate.value -= 100

     }

   }
   if (e.deltaY < 0){
  // val = index - 1 === -1 ? 0 : index - 1

     if (translate.value + 100 === 100) {
       translate.value += 0
     }else {

       translate.value += 100

     }



   }
   console.log(translate.value,'translate值')
    timer.value = null
 },100)
}

const go = (index) => {

  scrIndexActive.value = index
  translate.value = -100 * index



}

console.log(scrIndexActive.value,999)
// const cut =(ide)=>{
//   if (ide===scrIndexActive.value){
//     return true
//   }else if (scrIndexActive.value === 0 ){
//     console.log(1)
//     return true
//   }
// }
// cut()


</script>
<style lang="scss" scoped>


.inner-container {
  position: absolute;
  overflow-y: scroll;
}





.dyx{
  animation-duration:.8s;//重新设置动画时间

}
.container-fluid {
  --bs-gutter-x: 0;
}

.box {
  overflow-x: hidden;

}


.actives {
  background: url("@/assets/homeImg/activ.png");
  width: 100%;
  height: 100%;
  background-size: cover;
}

.box1 {
  width: 100vw;
  height: 100vh;
overflow: hidden;
  z-index: 100;
  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE 10+ */
  transition: all .5s;

}

.box2 {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  z-index: 100;
  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE 10+ */
  transition: all .5s;

}

.box3 {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  z-index: 100;
  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE 10+ */
  transition: all .5s;

}
.box4 {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  z-index: 100;
  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE 10+ */
  transition: all .5s;


}

.box5 {
  width: 100vw;
  height: 100vh;
  background: skyblue;
  overflow: hidden;

  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE 10+ */
  position: relative;
  transition: all .5s;
  z-index: 0;

}

.box6 {
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  transition: all .5s;

}


.schedule {
  height: 196px;
  width: 36px;
  position: fixed;
  right: 60px;
  top: 431px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  div {
    width: 30px;
    height: 35px;
    position: relative;
    cursor: pointer;

    i {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 2px;
      height: 2px;
      background: #ffffff;

    }
  }
}

</style>
