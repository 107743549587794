<template>

  <div class="body_main">
    <div class="banimg" ref="banimg">
      <tabBar :idIndex="+Route.query.navIde"></tabBar>
      <img v-if="parameter.name==='jb'" src="@/assets/homeImg/jbpbj.png">
      <img v-else-if="parameter.name==='xw'" src="@/assets/homeImg/xwpbj.png">
      <img v-else-if="parameter.name==='gs'" src="@/assets/homeImg/qyjm.png">

    </div>

    <div class="main">


     <div style="width: 100%;">
       <nav  style="margin-top: 14px;margin-left: 75px" aria-label="breadcrumb">
         <ol class="breadcrumb">
           <li class="breadcrumb-item"  style="cursor: pointer; font-size: 13px;"  @click="goBack(+Route.query.NavigationBarIndex,index)" v-for="(item,index) in nav[+Route.query.NavigationBarIndex - 1]" :key="index">{{item}}</li>
           <li class="breadcrumb-item" style="color: #1f6ed2;font-size: 13px;">{{navValue}}</li>

         </ol>
       </nav>
       <div class="box">

         <div class="title">
           {{ $t(list[+parameter.ide].title) }}
         </div>
         <div class="introduce">
           {{ $t(list[+parameter.ide].introduce) }}

         </div>
         <div class="text" v-html="$t(list[+parameter.ide].text)">


         </div>

       </div>
       <div v-if="flotWindow" :style="{height:flotWindow.text.length < 17? 'auto' : '73rem' }" class="flotWindow">
         <div class="NewsJBListTitle">
           {{ $t(flotWindow.title)}}
         </div>
         <div :style="{color:  +parameter.ide===index ? '#1f6ed2':'#000'}"  v-for="(item,index) in flotWindow.text" :key="index" class="listitem" @click="tabList(index)">
           {{ $t(item) }}
         </div>


       </div>

     </div>

    </div>


    <div ref="element2">
      <bottom></bottom>
    </div>
  </div>


</template>
<script setup>
import tabBar from '@/components/tabBar/tabIndex.vue'
import Bottom from '@/components/bottom/footNavigation.vue'
import { useRoute } from 'vue-router'
import { ref } from 'vue'
import router from '@/router'
const  banimg = ref(null)
const  element2 = ref(null)



const newList = ref([{
  title: 'news.title1',
  introduce: '发布者: 晨曦云 阅读: 1886',
  text:"news.text1"
},
  {
    title: 'news.title2',
    introduce: '发布者:晨曦云' + '  ' + '阅读:823',
    text:"news.text2"

  },
  {
    title: 'news.title3',
    introduce: '发布者:晨曦云' + '  ' + '阅读:2330',
    text:"news.text3"

  },
  {
    title: 'news.title4',
    introduce: '发布者:晨曦云' + '  ' + '阅读:3416',
    text:"news.text4"

  },
])

const jbList = ref([
  {
    title: 'NewsJBList.title1',
    introduce: '发布者:晨曦云' + '  ' + '阅读:1236',
    text: "NewsJBParticulars.text1"

  },
  {
    title: 'NewsJBList.title2',

    introduce: '发布者:晨曦云' + '  ' + '阅读:8881',
    text: "NewsJBParticulars.text2"

  },
  {
    title: 'NewsJBList.title3',
    introduce: '发布者:晨曦云' + '  ' + '阅读:5890',
    text: "NewsJBParticulars.text3"

  },
  {
    title: 'NewsJBList.title4',
    introduce: '发布者:晨曦云' + '  ' + '阅读:9833',
    text: "NewsJBParticulars.text4"

  },

  {
    title: 'NewsJBList.title5',
    introduce: '发布者:晨曦云' + '  ' + '阅读:3133',
    text: "NewsJBParticulars.text5"

  },
  {
    title: 'NewsJBList.title6',
    introduce: '发布者:晨曦云' + '  ' + '阅读:2438',
    text: "NewsJBParticulars.text6"

  },
  {
    title: 'NewsJBList.title7',
    introduce: '发布者:晨曦云' + '  ' + '阅读:5488',
    text: "NewsJBParticulars.text7"

  },
  {
    title: 'NewsJBList.title8',
    introduce: '发布者:晨曦云' + '  ' + '阅读:1348',
    text: "NewsJBParticulars.text8"

  },
  {
    title: 'NewsJBList.title9',
    introduce: '发布者:晨曦云' + '  ' + '阅读:3218',
    text: "NewsJBParticulars.text9"

  },
  {
    title: 'NewsJBList.title10',
    introduce: '发布者:晨曦云' + '  ' + '阅读:933',
    text: "NewsJBParticulars.text10"

  },
  {
    title: 'NewsJBList.title11',
    introduce: '发布者:晨曦云' + '  ' + '阅读:3091',
    text: "NewsJBParticulars.text11"

  },
  {
    title: 'NewsJBList.title12',
    introduce: '发布者:晨曦云' + '  ' + '阅读:2833',
    text: "NewsJBParticulars.text12"

  },
  {
    title: 'NewsJBList.title13',
    introduce: '发布者:晨曦云' + '  ' + '阅读:4133',
    text: "NewsJBParticulars.text13"

  },
  {
    title: 'NewsJBList.title14',
    introduce: '发布者:晨曦云' + '  ' + '阅读:3028',
    text: "NewsJBParticulars.text14"

  },
  {
    title: 'NewsJBList.title15',
    introduce: '发布者:晨曦云' + '  ' + '阅读:2830',
    text: "NewsJBParticulars.text15"

  },
  {
    title: 'NewsJBList.title16',
    introduce: '发布者:晨曦云' + '  ' + '阅读:1233',
    text: "NewsJBParticulars.text16"

  },
  {
    title: 'NewsJBList.title17',
    introduce: '发布者:晨曦云' + '  ' + '阅读:933',
    text: "NewsJBParticulars.text17"

  },



])

const bgcImg = ref()
const flotWindow = ref()
const nav = ref()
const navValue =ref('')
let  goBack

window.scrollTo({
  top: 0,
  behavior: 'smooth',
})
const list = ref()
const Route = useRoute() //获取到值

console.log(Route.query.name, 999)
const parameter = ref(Route.query)
if (parameter.value.name === 'xw') {
  console.log(newList, 999)

  list.value = newList.value
  flotWindow.value = {
    title: '新闻列表',
    text: ['news.title1', 'news.title2', 'news.title3','news.title4']
  }
  nav.value = [["首页","新闻列表"],["新闻","新闻列表"]]
  navValue.value = "新闻详情"
  bgcImg.value = "@/assets/homeImg/xwpbj.png"
  goBack = (navIde,ide)=>{
    if (navIde===1&& ide===0){

      router.push({
        path: '/',

      })
    }
    if (navIde===2 && ide===0){

      router.push({
        path: '/news',

      })
    }

    if (ide===1){
      router.go(-1)
    }


  }

} else if (parameter.value.name === 'jb') {
  list.value = jbList.value
  flotWindow.value = {
    title: '疾病列表',
    text: ['NewsJBList.title1', 'NewsJBList.title2', 'NewsJBList.title3',
      'NewsJBList.title4','NewsJBList.title5','NewsJBList.title6','NewsJBList.title7',
      'NewsJBList.title8','NewsJBList.title9','NewsJBList.title10','NewsJBList.title11','NewsJBList.title12',
      'NewsJBList.title13','NewsJBList.title14','NewsJBList.title15','NewsJBList.title16','NewsJBList.title17'
    ]
  }

  nav.value = [["首页"],["疾病"]]

  bgcImg.value = "@/assets/homeImg/jbpbj.png"

  navValue.value = "疾病详情"

  goBack = (navIde,ide)=>{
    if (navIde===1 && ide===0){

      router.push({
        path: '/',

      })
    }
    if (navIde===2 && ide===0){

      router.push({
        path: '/doIt',

      })
    }

    if (ide===1){
      router.go(-1)
    }


  }




} else if (parameter.value.name === 'gs') {

  list.value = [{
    title: 'whoWeAre.title',
    introduce: '发布者:晨曦云' + '  ' + '阅读:886',
    text: 'whoWeAre.introduce',

  }]


  nav.value = [["首页"],["公司介绍"]]
  navValue.value = "公司详情"
  goBack = (navIde,ide)=> {
    if (navIde === 1 && ide === 0) {

      router.push({
        path: '/',

      })
    }
    if (navIde === 2 && ide === 0) {

      router.push({
        path: '/whoWeAre',

      })
    }

    if (ide === 1) {
      router.go(-1)
    }

  }



    flotWindow.value = null
} else {
  console.log(999)
}

const tabList = (index) => {
  parameter.value.ide = index
}


</script>

<style lang="scss" scoped>

.body_main{
 //min-width: 1380px;
//width: 1200px;
  width: 100%;
  margin: 0 auto;
  background: #f5f5f5;
}
.banimg {
  width: 100%;
  height: 70vh;

  img {
    width: 100%;
    height: 100%;
  }
}

.main {
width: 1200px;
  margin: 0 auto;
  background: #f5f5f5;
  position: relative;
  display: flex;
  align-items: center;
  overflow: visible;

  .flotWindow {
    width: 300px;
    background: #FFFFFF;
    position: absolute;
    top: 5.1vh;
    right: -24rem;
    overflow: hidden;
    overflow-y: auto;
    //transform: translateY(-50%);
    padding: 15px;

    .NewsJBListTitle {
      height: 50px;
      line-height: 50px;
      width: 100%;
      color: #000;
      font-size: 18px;
      border-bottom: 2px solid #efefef;
      margin-bottom: 10px;
    }

    .listitem {
      font-size: 17px;
      line-height: 2.5rem;
      cursor: pointer;
      margin-bottom: 10px;

      &:hover {
        color: #00A2EA;
      }
    }
  }

  .box {
    width: 105rem;
    min-height: 750px;
    background: #FFFFFF;
    padding: 10px 20px;
    margin: 20px auto 20px;
    .title {
      width: 75%;
      /* height: 7rem; */
      text-align: justify;
      line-height: 3.2rem;
      font-size: 2rem;
      font-weight: 600;
      margin: 20px auto;
      word-wrap: break-word;
      word-break: break-all;
      display: flex;
      justify-content: center;

    }

    .introduce {
      width: 100%;
      height: 40px;
      line-height: 40px;
      text-align: center;
      font-size: 15px;
      color: #dddddd;
      border-top: 0.2rem solid #f6f6f6;
      padding-top: 15px;

    }

    .text {
      width: 100%;
      padding: 20px 40px;
      font-size: 17px;
      line-height: 32px;
      text-align: justify;
      text-indent: 32px;
    }
  }
}


</style>
