<template>
  <tabBar :idIndex="2"></tabBar>
  <div class="main">
    <div class="box1">
      <div class="title">What We Offer</div>
      <div class="text">
        {{$t('weOffer.offerText1')}}
      </div>
      <div class="button">
        <div @click="btnActive(-1)" :class="pageIndex===1? ' bt' : 'btActive'" class="left ">
         <i class="bi-chevron-left icon"></i>
        </div>
        <div class="page">
          <span :class="pageIndex===1&&'active'">01</span>
          <span class="active">/</span>
          <span :class="pageIndex===2&&'active'">02</span></div>
        <div @click="btnActive(+1)" :class="pageIndex===2? 'bt' : 'btActive '"  class="right ">
          <i class="bi-chevron-right icon"></i>

        </div>


      </div>
    </div>
    <div class="box2">
      <div class="boxImg1 animate__animated animate__fadeInRightBig">
        <img style="border-radius: 4px" src="@/assets/homeImg/off1.png">
      </div>
      <div class="boxImg2 animate__animated animate__fadeInRightBig dy">
        <img style="border-radius: 4px" src="@/assets/homeImg/off2.png">

      </div>
      <div class="bgcBlue animate__animated animate__fadeInBottomLeft">
        <div class="buleBox">
          <div class="Blue_head">
            <img  src="@/assets/homeImg/pz.png">
            <div class="text">01</div>
          </div>
          <div class="title">
            分子化合物技术
          </div>
          <div class="introduce">
            High throughput experiments are creating the world’s most diver
          </div>

        </div>
        </div>

    </div>
  </div>

  <bottom></bottom>
</template>
<script setup>
import tabBar from '@/components/tabBar/tabIndex.vue'
import Bottom from '@/components/bottom/footNavigation.vue'
import { ref } from 'vue'


const pageIndex = ref(1)

// const left = e => {
//   console.log(e, '555')
// }

const btnActive =(idex)=>{
  console.log(idex)
  if (idex>0){
    if (pageIndex.value>=2) return
    pageIndex.value++
  }else {
    if (pageIndex.value<=1) return
    pageIndex.value--

  }

  console.log(pageIndex.value)
}


</script>
<style lang="scss" scoped>

.dy{
  animation-duration:.8s;//重新设置动画时间
  animation-delay:.3s;
}

.main {
  width: 100vw;
  height: 100vh;
  background: url("@/assets/homeImg/offer.png") no-repeat;
  background-size: cover;
  overflow-x: hidden;
  position: relative;

  .active {
    color: #00A2EA;
    font-size: 24px;
  }

  .box1 {
    position: absolute;
    width: 359px;
    height: 408px;
    top: 306px;
    left: 260px;

    .title {
      width: 354px;
      font-size: 50px;
      font-weight: normal;
      color: #FFFFFF;
      white-space: nowrap;
      margin-bottom: 51px;

    }

    .text {
      width: 359px;
      font-size: 16Px;
      font-weight: normal;
      color: #FFFFFF;
      text-indent: 32px;
      line-height: 32px;
      text-align: justify;
      word-wrap: break-word;
      word-break: break-all;
    }

    .button {
      display: flex;
      height: 55px;
      width: 100%;
      margin-top: 15px;
      align-items: center;

      .page {
        color: #FFFFFF;
        font-size: 18px;
        margin: 0 28px;
      }

      .icon{
        color: #FFFFFF;
        font-size: 17px;
        line-height: 51px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      .btActive{
        position: relative;
        width: 51px;
        height: 51px;
        border: 1px solid #00a2ea;
        border-radius: 50%;
        cursor: pointer;
        background: #0b99f7;

      }

      .bt {
        position: relative;
        width: 51px;
        height: 51px;
        border: 1px solid #FFFFFF;
        border-radius: 50%;
        cursor: pointer;
        background: #aba7a7;



        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
}

.box2 {
  height: 424px;
  width: 988px;
  position: absolute;
  top: 380px;
  left: 673px;
  display: flex;
  justify-content: flex-end;

  .boxImg1 {
    width: 558px;
    height: 364px;
    border-radius: 4px;
    margin-right: 35px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .boxImg2 {
    width: 261px;
    height: 364px;
    border-radius: 4px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .bgcBlue {
    width: 30rem;
    height: 30rem;
    border-radius: 4px;
    background: url("@/assets/homeImg/bule.7.png") no-repeat;
    background-size: cover;
    position: absolute;
    left: 0;
    bottom: 0;
    padding: 0 25px 0 49px;

    .buleBox{
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    .Blue_head {
      width: 100%;
      height: 70px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 0.8rem;
      img {
        width: 70px;
        height: 70px;
      }

      .text {
        font-size: 50px;
        font-weight: normal;
        color: #FFFFFF;
        opacity: 0.67;

      }


    }

    .title {
      font-size: 24px;
      font-weight: normal;
      color: #FFFFFF;
      margin-top: 17px;

    }

    .introduce {
      width: 21.4rem;
      font-size: 16Px;
      font-weight: normal;
      color: #FFFFFF;
      margin-top: 1.8rem;
      text-indent: 3.2rem;
      line-height: 32px;
      text-align: justify;
      word-wrap: break-word;
      word-break: break-all;

    }
  }
}
</style>
