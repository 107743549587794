import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/Home/homePage.vue'
import whoWeAre from '../views/Home/whoWeAre.vue'
import weOffer from '../views/Home/WeOffer.vue'
import doIt from '../views/Doit/index.vue'
import peopleAndValue from '@/views/peopleAndValue/index.vue'
import news from '@/views/News'
import AListOfDetails from '@/views/AListOfDetails/AListOfDetails.vue'
import Particulars from '@/views/Page/particulars.vue'
import Map from '@/views/Page/Map.vue'
import FootNavigation from '@/components/bottom/footNavigation.vue'
const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {path:"/FootNavigation",
    name: 'FootNavigation',
    component: FootNavigation
  },
  {
    path: '/whoWeAre',
    name: 'whoWeAre',
    component: whoWeAre
  },
  {
    path: '/weOffer',
    name: 'weOffer',
    component: weOffer
  },
  {
    path: '/doIt',
    name: 'doIt',
    component: doIt
  },
  {
    path: '/peopleAndValue',
    name: 'peopleAndValue',
    component: peopleAndValue
  },
  {
    path: '/news',
    name: 'news',
    component: news
  },
  {
    path: '/AListOfDetails',
    name: 'AListOfDetails',
    component: AListOfDetails
  },

  {
    path: '/Particularst',
    name: 'Particulars',
    component: Particulars
  },
  {
    path: '/Map',
    name: 'Map',
    component: Map
  }


  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
