

function setRem () {
  // 320 默认大小16px; 320px = 20rem ;每个元素px基础上/16
  let htmlWidth = document.documentElement.clientWidth || document.body.clientWidth
  //得到html的Dom元素

  let htmlDom = document.getElementsByTagName('html')[0]
  //设置根元素字体大小
  console.log(htmlWidth >= 1280 && htmlWidth <= 1920 )
  if (htmlWidth >= 1280 && htmlWidth <= 1920 ) {
    htmlDom.style.fontSize = htmlWidth / 192 + 'px'
  }

  if (htmlWidth > 1920) {
    htmlDom.style.fontSize = 1920 / 192 + 'px'
  }
  if (htmlWidth < 1280){
    htmlDom.style.fontSize = 1280 / 192 + 'px'
  }

}

// 初始化
setRem()
window.onresize = function () {
  setRem()
}




