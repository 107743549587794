<template>
<!--  <tabBar style="z-index: 999999" v-if="flag" :idIndex="1"></tabBar>-->

  <div class="ProfileMain ">

    <div class="gs_Profile">
      <div class="gs_Profile_left">
        <div class="titleBox" :class="[esMate()&&'animate__zoomIn','animate__animated']">
          <div class="title"> {{$t("whoWeAre.text")}}</div>
          <div class="Profile"> {{$t("whoWeAre.title")}}</div>
          <div class="introduce">
            {{$t("whoWeAre.introduce2")}}
<!--              南京寰柏生物科技有限公司成立于2017年，是一家专注于心血管生物医药开发的公司，公司服务涵盖医药临床前研究的全过程，主要包括药物开发、药理学研究及血清标记物ELISA试剂盒开发。目前，公司已拥有一支具有丰富经验、熟练新药研发的核心技术团队，设有生物安全Ⅱ级细胞房-->
            <div style="position: fixed;z-index: 111111;
}"  class="animate__animated  sf">
              <img style="position: absolute" src="@/assets/homeImg/sifangdian.png">
            </div>
          </div>
          <div class="detailed">
            <div @click="gocompany"> {{$t("whoWeAre.b2")}}></div>
          </div>
        </div>
      </div>
      <div class="gs_Profie_right"  :class="esMate()&&['animate__zoomIn','animate__animated']">
        <img src="@/assets/homeImg/gszp.png">

      </div>
    </div>
    <div class="gs_Weare">
      <div style="  position: relative;" :class="esMate()&&['animate__fadeInLeftBig','animate__animated']">

      <span style="
      width: 50rem;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

">
        <span style="font-size: 2.6rem;color: #FFFFFF;"> {{$t("whoWeAre.t")}} </span>
        <span style="font-size: 16Px;color: #fff;margin-top: 3.3rem "> {{$t("whoWeAre.assistant")}}</span>
      </span>

      </div>
<!--      animate__fadeInRight-->
      <div  :class="[ide===1?'chakbox' : 'WeareBox',esMate()&&['animate__fadeInRight','yc1']]" class="WeareBox animate__animated" @mouseover="handleMouseover(1)">
        <div style="background: #2eaee5 ;" class="img-box">
        <img :class="ide===1 &&['animate__bounce']" class="WeareImg animate__animated " src="@/assets/homeImg/zhuanli.png">
        </div>

          <div :class="[esMate()&&'animate__zoomInLeft','dy']" class="title animate__animated ">  </div>
        <div :class="[esMate()&&'animate__zoomInLeft','dy']" class="text animate__animated dy"> {{$t("whoWeAre.x1")}} </div>
      </div>
      <div :class="[ide===2?'chakbox' : 'WeareBox',esMate()&&['animate__fadeInRight','yc2']]" class="WeareBox animate__animated"  @mouseover="handleMouseover(2)">
        <div  style="background: #cdecf9 ;"  class="img-box">
        <img :class="ide===2 &&['animate__bounce']" class="WeareImg animate__animated " src="@/assets/homeImg/pepe.png">
        </div>
        <div :class="[esMate()&&'animate__zoomInLeft','dy']" class="title animate__animated dy"></div>
        <div :class="[esMate()&&'animate__zoomInLeft','dy']" class="text animate__animated dy">{{$t("whoWeAre.x2")}}</div>
      </div>
      <div :class="[ide===3?'chakbox' : 'WeareBox',esMate()&&['animate__fadeInRight','yc3']]" class="WeareBox animate__animated" @mouseover="handleMouseover(3)">
<!--        animate__bounce-->

        <div style="background: #2eaee5 ;" class="img-box">
          <img :class="ide===3 &&['animate__bounce']" class="WeareImg animate__animated " src="@/assets/homeImg/td.png">
        </div>
        <div :class="[esMate()&&['animate__zoomInLeft','dy']]" class="title animate__animated "></div>
        <div :class="[esMate()&&['animate__zoomInLeft','dy']]" class="text animate__animated ">{{$t("whoWeAre.x3")}}</div>

      </div>
    </div>
  </div>

</template>
<script setup>
// import tabBar from '@/components/tabBar/tabIndex.vue'
import { defineProps, ref, toRefs } from 'vue'
import router from '@/router'
import { useRoute } from 'vue-router'

const props = defineProps({
  flag: {
    type: Boolean,
    default: true,
  },
  scrIndexActive:{
    type: Number,
  },
  NavigationBarIndex:{
    type: Number,
  }
})
// eslint-disable-next-line no-unused-vars
const {scrIndexActive ,flag, NavigationBarIndex} = toRefs(props)

// const scrIndexActive = ref()

//   scrIndexActive.value = toRefs(props).scrIndex
// console.log(scrIndexActive.value,111)


const Route = useRoute() //获取到值
const  esMate = ()=>{
  if (scrIndexActive.value===1){
    return true
  }
  if (Route.query.num){
 return true
  }
}






const gocompany = () => {
  router.push({
    path: '/Particularst',
    query: {
      name: 'gs',
      ide: 0,
      navIde:1,
      NavigationBarIndex:NavigationBarIndex.value ? NavigationBarIndex.value : 2
    }
  })
}

const ide = ref(2)
const handleMouseover = (index) => {
  console.log(index)
  ide.value = index
}
</script>

<style lang="scss" scoped>

.WeareBox .img-box{
  width: 10rem !important;
  height: 10rem !important;
  border-radius: 100%;

}
@media screen and (min-width: 2000px) {
  .sf{

    bottom: 1.4rem;
    right: 14.3rem;
  }
}



@media screen and (max-width: 1920px) {
  .sf{

    bottom: -30px;
    right: 143px;
  }
}
@media screen and (max-width: 1566px) {
  .sf{

    bottom: -80px;
    right: 145px;
  }
}
@media screen and (max-width: 1450px) {
  .sf{

    bottom: -120px;
    right: 145px;
  }
}
@media screen and (max-width: 1390px) {
  .sf{

    bottom: -230px;
    right: 145px;
  }
}

.dy{
  animation-duration:1.5s;//重新设置动画时间
  animation-delay:1s;
}
.dy2{
  animation-duration:1.5s;//重新设置动画时间
  animation-delay:1.5s;

}

.yc1{
  animation-duration:1s;//重新设置动画时间

}
.yc2{
  animation-duration:1s;//重新设置动画时间

  -webkit-animation-delay:.5s;//延迟执行动画
  animation-delay:.5s;
}
.yc3{
  animation-duration:1s;//重新设置动画时间

  -webkit-animation-delay:1s;//延迟执行动画
  animation-delay:.8s;
}


.gs_Profie_right {
width: 100%;
min-width: 600px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.ProfileMain {
  width: 100%;
  height: 100vh;
  background: url("@/assets/homeImg/profileBgc.jpg") no-repeat;
  background-size: 100% 100%;

  .gs_Profile {
    height: 65vh;
    width: 100%;
    display: flex;

    .gs_Profile_left {
     min-width: 640px;
      height: 65vh;
      position: relative;
      z-index: 999;
   padding-left: 60px;
      .titleBox {
        width: 100%;
        height: 462px;
        position: absolute;
        top: 80px;

        .title {
          font-size: 40px;
          font-weight: normal;
          color: #FFFFFF;
          margin: 60px 0 25px;
        }

        .Profile {
          font-size: 28px;
          font-weight: normal;
          color: #FFFFFF;
          margin-bottom: 25px;

        }

        .introduce {
          width: 364px;

          font-size: 16Px;
          color: #FFFFFF;
          text-indent: 32px;
          line-height: 32px;
          text-align: justify;
          word-wrap: break-word;
          word-break: break-all;
        }

        .detailed {

          height: 30px;
          margin-top: 30px;
          font-size: 17px;
          font-weight: normal;
          color: #FFFFFF;

          div {
            border-bottom: 2px solid #FE6601;
            display: inline-block;
            padding-bottom: 9px;
            cursor: pointer;
          }
        }
      }

    }

    .gs_Profile_right {
      height: 65vh;
      width: 1280px;
      background: red;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

    }
  }

  .gs_Weare {
    height: 35vh;
    width: 100%;
    display: flex;

    div:nth-child(1) {
      width: 640px;
      height: 100%;
      background: #007DB3;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

    }

    .chakbox {
      background: #FFFFFF !important;
      box-shadow: 5px 0 20px -5px rgba(0, 0, 0, 0.17), /* 左侧阴影 */
      -5px 0 20px -5px rgba(0, 0, 0, 0.17); /* 右侧阴影 */
      z-index: 1;
      transition: all 1s;
    }

    .WeareBox {
     width: 433px;
      min-width: 300px;
      height: 100%;
      background:#e4e6f2;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex: 1;


      .WeareImg {
        width: 51px;
        height: 51px;
        object-fit: contain;
      }

      .title {
        font-size: 38px;
        font-weight: normal;
        color: #222222;
        margin: 18px 0;
      }

      .text {
        font-size: 34px;
        font-weight: normal;
        color: #818181;
      }


      //&:hover {
      //  background: #FFFFFF;
      //  box-shadow: 5px 0 20px -5px rgba(0, 0, 0, 0.17), /* 左侧阴影 */
      //  -5px 0 20px -5px rgba(0, 0, 0, 0.17); /* 右侧阴影 */
      //  z-index: 99;
      //  transition: all 1s;
      //
      //
      //}


    }


  }

}


</style>
