<template>
  <tabBar :idIndex="+Route.query.navIde"></tabBar>
  <div class="main">
    <div class="bgc">
      <img src="@/assets/homeImg/listbgc.png">
    </div>


    <div class="list">
      <div class="boxList">
        <div class="title">
          News & Views
        </div>
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item" style="font-size: 13px; cursor: pointer" @click="goBack(+Route.query.NavigationBarIndex)">{{nav[+Route.query.NavigationBarIndex - 1]}} </li>
            <li class="breadcrumb-item active"  aria-current="page" style="font-size: 13px;color: #1f6ed2;">新闻列表</li>

          </ol>
        </nav>
        <!--        <div class="Newsbox_Text">-->
        <div v-for="(item,index) in list[Route.query.ide]" :key="index" class="newsBox">
          <div class="headTitle">
            <div class="headTitle_t" @click="goParticulars(index)"><i class="dot"></i> <span class="headTitle_d">{{ $t(item.title)  }}</span></div>
            <span class="headTitle_time">{{ item.time }}</span>
          </div>
          <div class="introduceText">
            <span>{{ $t(item.introduceText)+"....."}}</span>
            <span class="headTitle_xx" @click="goParticulars(index)">详细</span>
          </div>
        </div>


      </div>
    </div>
  </div>
  <Bottom></Bottom>

</template>
<script setup>
import tabBar from '@/components/tabBar/tabIndex.vue'
import Bottom from '@/components/bottom/footNavigation.vue'
import { useRoute } from 'vue-router'
import router from '@/router'
import { ref } from 'vue'

// console.log(useI18n(),9999)

window.scrollTo({
  top: 0,
  behavior: 'smooth',
})

const Route = useRoute() //获取到值
console.log(Route.query,999)
// const parameter = Route.query
const goParticulars = (index) => {
  router.push({
    path: '/Particularst',
    query: {
      name: Route.query.name,
      ide: index,
      navIde:+Route.query.navIde,
      NavigationBarIndex:+Route.query.NavigationBarIndex
    }
  })

}

const  goBack = (ide) =>{
  if (ide===1){

    router.push({
      path: '/',

    })
  }
  if (ide===2){
    router.push({
      path: '/news',

    })
  }
}



const nav = ref(["首页","新闻"])


const list = ref([[
  {
    title: "news.title1",
    time: '05-19',
    introduceText: 'news.omit1'
  },
  {
    title: "news.title2",
    time: '05-19',
    introduceText: 'news.omit2'
  },
  {
    title: 'news.title3',
    time: '05-19',
    introduceText: 'news.omit3'
  },
  {
    title: 'news.title4',
    time: '05-19',
    introduceText: 'news.omit4'
  }
],
  [
    {
      title: 'NewsJBList.title1',
      time: '05-19',
      introduceText: "NewsJBList.text1"
    }, {
    title: 'NewsJBList.title2',
    time: '05-19',
    introduceText: "NewsJBList.text2"
  }, {
    title:'NewsJBList.title3',
    time: '05-19',
    introduceText: 'NewsJBList.text3'
  },
    {
      title:'NewsJBList.title4',
      time: '05-19',
      introduceText: 'NewsJBList.text4'
    },
  ]
])


</script>
<style lang="scss" scoped>

.breadBox{
  width: 100%;
  height: 40px;
  padding: 20px 265px;
}



.main {
  width: 100%;
  //height: 200vh;
  overflow-x: hidden;
  position: relative;
  min-width: 1280px;
}

.bgc{
  img{
    width: 100%;
    height: 100%;
  }
}
.list {
  width: 100%;
  padding: 86px 265px 105px;

  .boxList {
    width: 100%;
    //background: pink;

    .title {
      width: 100%;

      height: 73px;
      border-bottom: 1px solid rgba(255, 255, 255, .5);

      font-size: 52px;
      font-weight: normal;
      color: #202137;

    }
  }


}

//.Newsbox_Text {
//  width: 100%;
//  height: 100%;
//  display: flex;
//  flex-direction: column;
//  justify-content: flex-end;
//  align-items: flex-end;

.newsBox {
  height: 181px;
  width: 100%;
  padding: 51px 18px 75px;
  border-bottom: 2px solid #F7F7F8;
  cursor: pointer;

  &:hover {
    .headTitle_xx {
      height: 39px;
      display: inline-block;
      font-size: 15px;
      font-weight: normal;
      color: #347BD5;
      opacity: 1;
      cursor: pointer;
      line-height: 39px;
    }
  }

  .headTitle {
    width: 100%;
    display: flex;
    justify-content: space-between;


  }

  .introduceText {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 24px 0;
line-height: 32px;
    span:nth-child(1) {
      width: 80%;
      height: 39px;
      font-size: 14Px;
      font-weight: normal;
      color: #787A87;
      padding-left: 40px;
    }

    .headTitle_xx {
      height: 39px;
      display: inline-block;
      font-size: 15Px;
      font-weight: normal;
      color: #347BD5;
      cursor: pointer;
      line-height: 39px;
    }


  }
}

.dot {
  width: 6px;
  height: 6px;

  background: #C1C1C1;

  border-radius: 50%;
  margin-right: 35px;

}

.headTitle_d {
  color: #202137;
  font-size: 20px;
}

.headTitle_time {

  font-size: 18px;
  font-weight: normal;
  color: #787A87;
}

.headTitle_t {
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    .headTitle_d {
      color: #347BD5;
      font-size: 20px;
    }

    .dot {
      width: 6px;
      height: 6px;

      background: #347BD5;
      border-radius: 50%;
      margin-right: 35px;

    }


  }

}


</style>
